import { faBell } from '@fortawesome/pro-regular-svg-icons'
import {
  faArrowLeft,
  faArrowToBottom,
  faArrowToTop,
  faCheck,
  faCheckCircle,
  faClock,
  faExclamationTriangle,
  faEye,
  faPaperPlane,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios, { AxiosError, AxiosResponse } from 'axios'
import querystring from 'querystring'
import { DataSource } from '../../common/enum/data-source'
import { useBackButtonUpdate } from '../../common/context/back-button.context'
import useReactRouter from 'use-react-router'
import { Title } from '../title/title'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import ReactGA from 'react-ga'

import { Button, Col, Container, Label, Nav, Row, Spinner } from 'reactstrap'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { Loading } from '../loading'
import {
  CentreUnit,
  PathParam,
  UnitCandidate,
  ValuationStatus,
} from '../../types'
import { CandidatesListContainer } from '../candidates-list/candidates-list-container'
import { ManageUploadModal } from '../upload/manage-upload'
import { isForbidden, isBadRequest } from '../axios-error-helpers'
import { SimpleMessage } from '../simple-message/simple-message'
import { SimpleErrorMessage } from '../simple-message/simple-error-message'
import { DownloadModal } from '../upload/download-modal'
import { ApproveModal } from '../upload/approve-modal'
import {
  adjustTimestampToUtc,
  formatDate,
  minusDays,
} from '../candidates-list/constants'
import { useAuth0 } from '../../auth'
import {
  isHOC,
  unitInProgress,
  isCAAdmin,
  isOCR,
  escapeSlashes,
} from '../../util'
import { HOCApproveModal } from '../hoc-approve-modal'
import getTextFromToken from '../../tokenised-text'
import { NotAvailable } from '../not-available'
import { useConfig } from '../../common/hooks/use-remote-config'
import { Redirect, useHistory } from 'react-router-dom'
import { InlineErrorMessageNoBorder } from '../simple-message/inline-error-message'
import { CAAdminBanner } from '../ca-admin-banner'
import { getSubTitleByUnit } from '../../common/services/centre-unit-service'
import { format } from 'date-fns'

export const SubjectPage: React.FC = (): JSX.Element => {
  const { config } = useConfig()
  const { match, location } = useReactRouter<PathParam>()
  const { user } = useAuth0()
  const [uploadModalShow, setUploadModalShow] = useState(false)
  const [downloadModalShow, setDownloadModalShow] = useState(false)
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [approvalDisabled, setApprovalDisabled] = useState(true)
  const [showHOCApproval, setShowHOCApproval] = useState(false)
  const [uploadedWithErr, setUploadedWithErr] = useState(false)
  const [unit, setUnit] = useState<CentreUnit | undefined>()
  const history = useHistory()

  const centreId = match.params.id
  const unitId = match.params.unitId

  const backButtonUpdate = useBackButtonUpdate()

  useEffect(() => {
    backButtonUpdate({
      returnUrl: `/centres/${centreId}`,
      text: 'Dashboard',
    })
    // avoid perpetual re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location])

  const gradeFilter = useMemo<string>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )

    if (!qs.grade) {
      return 'ALL'
    }

    if (Array.isArray(qs.grade)) {
      return qs.grade[0]
    }

    return qs.grade
  }, [location])

  const [valuationStatus, setValuationStatus] = useState<string>('')
  useEffect(() => {
    setValuationStatus(unit?.valuationStatus || '')
  }, [unit])

  const unitClosed = useMemo(() => unit?.closed === true, [unit])

  const viewOnly = useMemo(
    () =>
      !unitInProgress(unit?.valuationStatus) ||
      (user !== undefined && isCAAdmin(user, true)) ||
      unitClosed,
    [user, unit, unitClosed]
  )

  const lastUpdatedString = useMemo(
    () =>
      escapeSlashes(
        formatDate(new Date(unit?.lastUpdated || Date.now()), true, true),
        ' / '
      ),
    [unit]
  )

  const getCandidatesMemo = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/units/${unitId}/candidates`,
    }),
    [centreId, unitId]
  )
  const getCandidatesTask = useAsyncTaskAxios<AxiosResponse<UnitCandidate[]>>(
    axios,
    getCandidatesMemo
  )

  useAsyncRun(getCandidatesTask)

  useEffect(() => {
    if (uploadedWithErr) {
      setShowErrors(true)
    }
  }, [getCandidatesTask.result, uploadedWithErr])

  const isApprovalOpenCallback = (state: boolean) => {
    setShowApprovalModal(state)
  }

  const patchUnitMemo = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/units/${unitId}`,
      method: 'patch',
    }),
    [centreId, unitId]
  )
  const patchUnitTask = useAsyncTaskAxios<AxiosResponse<CentreUnit>>(
    axios,
    patchUnitMemo
  )

  const badPatch = useMemo(() => {
    if (!patchUnitTask.error) {
      return false
    }
    return isBadRequest((patchUnitTask.error as AxiosError).response)
  }, [patchUnitTask.error])

  const getCentreUnitMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/units/${unitId}`,
    }
    // badPatch should be kept in the dependency array for approval or rejection fail
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreId, unitId, badPatch])

  const getCentreUnitTask = useAsyncTaskAxios<AxiosResponse<CentreUnit>>(
    axios,
    getCentreUnitMemo
  )
  useAsyncRun(getCentreUnitTask)

  const forbidden = useMemo(() => {
    if (!getCentreUnitTask.error) {
      return false
    }
    return isForbidden((getCentreUnitTask.error as AxiosError).response)
  }, [getCentreUnitTask.error])

  useEffect(() => setUnit(getCentreUnitTask.result?.data), [
    getCentreUnitTask.result,
  ])

  const viewOnlyState = useMemo<string>(() => {
    if (user && isHOC(user) && valuationStatus !== ValuationStatus.APPROVED) {
      return 'hoc'
    }
    return valuationStatus
    // patchSyllabusTask.error should be kept in the dependency array for refreshing students list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuationStatus, user, patchUnitTask.error])

  useEffect(() => {
    if (patchUnitTask.result) {
      setUnit(patchUnitTask.result.data)
    }
  }, [patchUnitTask.result])

  const approvalDisabledCallback = (state: boolean) => {
    setApprovalDisabled(state)
  }

  const setUnitCallback = (unit: CentreUnit | undefined) => {
    setUnit(unit)
  }

  const onSendBack = useCallback(() => {
    ReactGA.event({
      category: 'Grade Submission',
      action: 'Reject',
      label: unitId,
    })

    patchUnitTask.start({
      data: {
        valuationStatus: ValuationStatus.COMPLETE,
      },
    })
  }, [patchUnitTask, unitId])

  const getDeadlineDate = (timestamp: number) =>
    isOCR()
      ? format(adjustTimestampToUtc(minusDays(timestamp, 7)), 'd MMMM yyyy')
      : '16th June 2021'

  if (centreId !== centreId.toUpperCase()) {
    return <Redirect to={`/centres/${centreId.toUpperCase()}/unit/${unitId}`} />
  }
  if (user && !isCAAdmin(user) && Date.now() < Number(config.available)) {
    return <NotAvailable />
  }

  if (unitId && getCentreUnitTask.result) {
    return (
      <>
        <CAAdminBanner centreId={centreId} linkBack />
        {showErrors && (
          <Nav className="error-nav bg-danger text-white font-weight-bold py-4">
            <Container>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                fixedWidth
                className="mr-3"
              />
              This {getTextFromToken('syllabus')} contains errors
            </Container>
          </Nav>
        )}

        {patchUnitTask.error && (
          <Nav className={`text-white font-weight-bold py-3 bg-danger`}>
            <Container>
              <InlineErrorMessageNoBorder
                isBgDanger
                title={`Failed to update ${getTextFromToken(
                  'syllabus'
                )}, please try again and if the problem persists please contact us`}
              />
            </Container>
          </Nav>
        )}
        {unitInProgress(unit?.valuationStatus) && unitClosed && (
          <Nav className={`text-white font-weight-bold py-3 bg-danger`}>
            <Container>
              <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
              {/** feature/ISPR-1517 hide ties and ranks **/}
              {/* (INCOMPLETE) The deadline for submitting grades and rank orders <u>has now passed,</u> you can view and download the data. */}
              (INCOMPLETE) The deadline for submitting grades{' '}
              <u>has now passed,</u> you can view and download the data.
            </Container>
          </Nav>
        )}
        {unit?.valuationStatus === ValuationStatus.SUBMITTED && unitClosed && (
          <Nav className={`text-white font-weight-bold py-3 bg-danger`}>
            <Container>
              <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
              {/** feature/ISPR-1517 hide ties and ranks **/}
              {/* (INCOMPLETE) The deadline for submitting grades and rank orders <u>has now passed,</u> you can view and download the data. */}
              (INCOMPLETE) The deadline for submitting grades{' '}
              <u>has now passed,</u> you can view and download the data.
            </Container>
          </Nav>
        )}
        {viewOnly && !unitInProgress(valuationStatus) && !unit?.closed && (
          <Nav
            className={`text-white font-weight-bold py-3 ${
              ['approved', 'hoc'].includes(viewOnlyState)
                ? 'bg-success'
                : 'bg-primary'
            }`}
          >
            <Container>
              <Row className="align-items-center">
                {['approved', 'hoc', 'submitted'].includes(viewOnlyState) && (
                  <Col>
                    <span className="d-inline-flex align-items-center">
                      <FontAwesomeIcon
                        icon={
                          // @ts-ignore
                          {
                            approved: faCheckCircle,
                            hoc: faBell,
                            submitted: faClock,
                          }[viewOnlyState]
                        }
                        fixedWidth
                        className="mr-3"
                      />
                      {
                        // @ts-ignore
                        {
                          approved: `This ${getTextFromToken(
                            'syllabus'
                          )} has been approved and submitted`,
                          hoc: `This ${getTextFromToken(
                            'syllabus'
                          )} is waiting for your approval`,
                          submitted: `This ${getTextFromToken(
                            'syllabus'
                          )} is waiting for approval from the Head of Centre`,
                        }[viewOnlyState]
                      }
                    </span>
                  </Col>
                )}
                {['hoc'].includes(viewOnlyState) &&
                  !(patchUnitTask.started && patchUnitTask.pending) && (
                    <>
                      <Col xs="auto">
                        <Button
                          color="link"
                          className="px-0 text-white"
                          onClick={onSendBack}
                          data-testid="syllabus-page-send-for-review-button"
                        >
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="mr-2"
                          />
                          <span className="underline">
                            Send back for a further review
                          </span>
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="white"
                          className=" text-success "
                          onClick={() => {
                            setShowHOCApproval(true)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            fixedWidth
                            className="mr-2"
                          />
                          APPROVE
                        </Button>
                      </Col>
                    </>
                  )}
                {viewOnlyState === 'hoc' &&
                  patchUnitTask.started &&
                  patchUnitTask.pending && (
                    <Col xs="auto">
                      <Spinner size="sm" className="mr-2" />
                      Updating
                    </Col>
                  )}
              </Row>
            </Container>
          </Nav>
        )}

        <div
          className={`px-3 mb-5 ${
            viewOnly ? 'bg-lightButNotTooLight py-42' : 'bg-light py-45'
          }`}
        >
          <Container>
            {unit && !viewOnly && (
              <>
                <Row>
                  <Col className="my-3">
                    <Title
                      title={unit?.unitName || ''}
                      subTitle={getSubTitleByUnit(unit)}
                      ancillery={`${unit?.totalCandidates} candidates`}
                    />
                  </Col>
                  {getCandidatesTask.result && (
                    <Col
                      xs={24}
                      md="auto"
                      className="text-center text-md-right mt-0 mt-md-3 mb-4"
                    >
                      <Button
                        color="e4"
                        className="mr-3 text-center text-secondary"
                        onClick={() => setDownloadModalShow(true)}
                        data-testid="syllabus-page-csv-template-download-button"
                      >
                        <FontAwesomeIcon icon={faArrowToBottom} fixedWidth />
                        <div>Download Template</div>
                      </Button>
                      <Button
                        color="e4"
                        className="mr-3 text-center text-secondary"
                        onClick={(e) => {
                          e.preventDefault()
                          setUploadModalShow(!uploadModalShow)
                        }}
                        data-testid="syllabus-page-csv-template-upload-button"
                      >
                        <FontAwesomeIcon icon={faArrowToTop} fixedWidth />
                        <div>Upload Template</div>
                      </Button>
                      <Button
                        disabled={approvalDisabled}
                        color="success"
                        className="text-center text-white"
                        onClick={(e) => {
                          e.preventDefault()
                          setShowApprovalModal(!showApprovalModal)
                        }}
                        data-testid="syllabus-page-send-for-approval-button"
                      >
                        <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
                        <div>Send for approval</div>
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row className="mb-4">
                  <Col lg={11} className="text-dark">
                    {approvalDisabled ? (
                      // ** feature/ISPR-1517 hide ties and ranks **
                      // <div>
                      //   Enter your candidates' grades and rank order. Your data
                      //   is saved automatically. Send for approval once all data
                      //   is correct. You can:
                      // </div>
                      <div>
                        <div>{getTextFromToken('enterGrades')}</div>
                        <div>
                          Send for approval once all data is correct. You can:
                        </div>
                      </div>
                    ) : (
                      <div>
                        All data has been entered / uploaded correctly, checked
                        and automatically saved.{' '}
                        <strong>You can send for approval now.</strong>
                      </div>
                    )}
                  </Col>
                </Row>

                <Row>
                  {approvalDisabled && (
                    <Col className="text-dark">
                      <ul className="list-inline font-weight-bold mb-0">
                        <li className="list-inline-item mr-3">
                          <span className="mr-2">&bull;</span> Download and
                          upload templates
                        </li>
                        <li className="list-inline-item mr-3">
                          <span className="mr-2">&bull;</span> Enter or edit
                          grades on screen
                        </li>
                        <li className="list-inline-item mr-3">
                          <span className="mr-2">&bull;</span> Check for errors
                        </li>
                      </ul>
                    </Col>
                  )}
                  {!viewOnly && (
                    <Col
                      xs={approvalDisabled ? 'auto' : '24'}
                      className="text-secondary font-weight-bold text-right"
                    >
                      <FontAwesomeIcon icon={faClock} className="mr-2" />
                      {`Deadline is ${getDeadlineDate(unit.deadline)}`}
                    </Col>
                  )}
                </Row>
              </>
            )}

            {unit && viewOnly && (
              <Row>
                <Col>
                  <Col>
                    <h3
                      className={`h5 mb-1 font-weight-bold ${
                        viewOnlyState === 'approved'
                          ? 'text-success'
                          : 'text-primary'
                      }`}
                    >
                      {unit?.unitName}
                    </h3>
                    <div className="font-larger font-weight-bold text-secondary">
                      {getSubTitleByUnit(unit)}
                      <>
                        <span className="text-black37 mx-2">|</span>{' '}
                        {`${unit?.totalCandidates} candidates`}
                      </>
                    </div>
                  </Col>
                </Col>
                <Col className="text-right">
                  <Label
                    className="d-flex justify-content-end font-weight-bold font-normal"
                    style={{ lineHeight: '16px' }}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faEye} />
                    This is a view only mode
                  </Label>
                  <Label
                    className="d-flex justify-content-end opacity-59 font-weight-semi-bold"
                    style={{ lineHeight: '16px' }}
                  >
                    Last updated on {lastUpdatedString}
                  </Label>
                </Col>
              </Row>
            )}
          </Container>
        </div>
        {getCandidatesTask.pending && (
          <Container>
            <SimpleMessage
              className="mb-5"
              icon={<Loading className="d-block mx-auto" />}
              title="Retrieving candidates..."
            />
          </Container>
        )}
        {!forbidden && !getCentreUnitTask.error && getCandidatesTask.error && (
          <Container>
            <SimpleErrorMessage
              title={`Failed to load candidates, please refresh and if the problem persists contact your system administrator`}
              allowPageRefresh
            />
          </Container>
        )}
        {unit && getCandidatesTask.result && (
          <>
            <DownloadModal
              {...getCentreUnitTask.result?.data}
              onClose={() => setDownloadModalShow(!downloadModalShow)}
              isOpen={downloadModalShow}
            />
            <ManageUploadModal
              onUploadComplete={(val: boolean) => {
                setUploadedWithErr(val)
                setUploadModalShow(false)
                getCentreUnitTask.start()
                getCandidatesTask.start()
              }}
              onClose={() => {
                setUploadModalShow(false)
              }}
              {...getCentreUnitTask.result?.data}
              isOpen={uploadModalShow}
            />
            <ApproveModal
              {...getCentreUnitTask.result.data}
              isOpen={showApprovalModal}
              setSyllabusCallback={setUnitCallback}
              isOpenCallback={isApprovalOpenCallback}
            />
            <CandidatesListContainer
              approvalDisabled={approvalDisabledCallback}
              gradeFilter={gradeFilter}
              // ** feature/ISPR-1517 hide ties and ranks **
              // allowTies={syllabus.tiesAllowed}
              candidates={getCandidatesTask.result?.data}
              showErrors={showErrors}
              toggleShowErrors={(newShow) => {
                setShowErrors(newShow)
              }}
              downloadCallback={() => setDownloadModalShow(true)}
              centreId={centreId}
              unitId={unitId}
              lastUpdated={Number(unit?.lastUpdated)}
              viewOnly={viewOnly}
              unit={unit}
            />
          </>
        )}
        {unit && (
          <HOCApproveModal
            unit={unit}
            isOpen={showHOCApproval}
            close={() => setShowHOCApproval(false)}
            approve={() => {
              ReactGA.event({
                category: 'Grade Submission',
                action: 'Approve',
                label: unitId,
              })
              setShowHOCApproval(false)
              patchUnitTask.start({
                data: { valuationStatus: ValuationStatus.APPROVED },
              })
            }}
          />
        )}
      </>
    )
  }
  return (
    <Container className="mt-5">
      {getCentreUnitTask.pending && (
        <SimpleMessage
          className="mb-5"
          icon={<Loading className="d-block mx-auto" />}
          title={`Retrieving ${getTextFromToken('syllabus')}...`}
        />
      )}
      {forbidden && (
        <SimpleErrorMessage title="You do not have permission to submit for this centre" />
      )}
      {!forbidden && getCentreUnitTask.error && (
        <SimpleErrorMessage
          title={`Failed to load ${getTextFromToken(
            'syllabus'
          )}, please refresh and if the problem persists contact your system administrator`}
          allowPageRefresh
        />
      )}
    </Container>
  )
}
