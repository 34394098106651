import React, { useCallback, useEffect, useMemo, useState } from 'react'
import querystring from 'querystring'
import { useBackButtonUpdate } from '../../common/context/back-button.context'
import useReactRouter from 'use-react-router'
import { keys } from 'lodash'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Nav,
  Row,
  UncontrolledDropdown,
} from 'reactstrap'
import { TabList } from '../tabs/tab-list'
import { Tab } from '../tabs/subcomponents/tab'
import { TabContentLabelWithCount } from '../tab-content-label-with-count'
import {
  CentreUnit,
  CentreUnitDto,
  Qualification,
  ValuationStatus,
} from '../../types'
import { UnitProgress } from '../unit-progress/unit-progress'

import { Loading } from '../loading'
import { isForbidden } from '../axios-error-helpers'
import { SimpleErrorMessage } from '../simple-message/simple-error-message'
import { SimpleMessage } from '../simple-message/simple-message'
import { SearchBar } from '../search-bar/search-bar'
import { formatDate } from '../candidates-list/constants'
import {
  escapeSlashes,
  isCAAdmin,
  isCambridgeInternational,
  isCentreUser,
  isHOC,
  isOCR,
  unitInProgress,
} from '../../util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarCheck,
  faCheck,
  faSortDown,
} from '@fortawesome/pro-solid-svg-icons'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { useAuth0 } from '../../auth'
import ReactMarkdown from 'react-markdown'
import { useConfig } from '../../common/hooks/use-remote-config'
import { CentreGradeButton } from './centre-grade-report/centre-grade-report'

import ciIntroContent from './ciIntroContent.md'
import ocrIntroContent from './ocrIntroContent.md'
import ocrHoCIntroContent from './ocrHoCIntroContent.md'
import getTextFromToken from '../../tokenised-text'
import { NotAvailable } from '../not-available'
import { useHistory } from 'react-router-dom'
import { CAAdminBanner } from '../ca-admin-banner'
import { withCentreId, withExpiration } from '../../enrichers'

const VALUATION_ORDER = [
  '---',
  ValuationStatus.SUBMITTED,
  ValuationStatus.COMPLETE,
  ValuationStatus.INCOMPLETE,
  ValuationStatus.NOT_STARTED,
  ValuationStatus.APPROVED,
]

const SORT_UNIT = (a: CentreUnit, b: CentreUnit) =>
  a.unitName?.localeCompare(b.unitName) ||
  a.unitId?.localeCompare(b.unitId) ||
  (VALUATION_ORDER.indexOf(a.valuationStatus) || 100) -
    (VALUATION_ORDER.indexOf(b.valuationStatus) || 100)

const STATUS_FILTERS: { [key: string]: string } = {
  ALL: 'All',
  [ValuationStatus.NOT_STARTED]: 'Not Started',
  [ValuationStatus.INCOMPLETE]: 'In Progress',
  [ValuationStatus.COMPLETE]: 'Ready for Approval',
  [ValuationStatus.SUBMITTED]: 'Awaiting Approval',
  [ValuationStatus.APPROVED]: 'Approved and Submitted',
  [ValuationStatus.OPENED]: 'All Opened',
}

export const CentreUnitPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => {
  const { config } = useConfig()

  const [searchFilter, setSearchFilter] = useState('')
  const [showReopenedOnly, setShowReopenedOnly] = useState(true)
  const backButtonUpdate = useBackButtonUpdate()
  const history = useHistory()
  const { location } = useReactRouter()
  const { user } = useAuth0()
  const getCentreUnitMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/units`,
    }
  }, [centreId])

  useEffect(() => {
    if (isCambridgeInternational()) {
      if (user && isCAAdmin(user)) {
        backButtonUpdate({
          returnUrl: '/centres',
          text: 'Home',
        })
        return
      }
      backButtonUpdate(null)
      return
    }
    if (user && isCAAdmin(user)) {
      backButtonUpdate({
        returnUrl: '/centres',
        text: 'Internal dashboard',
      })
      return
    }
    backButtonUpdate({
      returnUrl: '/',
      text: 'Home',
    })
  }, [history.location])

  const getCentreUnitTask = useAsyncTaskAxios<AxiosResponse<CentreUnitDto[]>>(
    axios,
    getCentreUnitMemo
  )

  useAsyncRun(getCentreUnitTask)

  const reopenedCount = useMemo(() => {
    return getCentreUnitTask.result
      ? getCentreUnitTask.result?.data.filter(
          (x) =>
            !x.closed &&
            (unitInProgress(x.valuationStatus) ||
              x.valuationStatus === ValuationStatus.SUBMITTED)
        ).length
      : 0
  }, [getCentreUnitTask.result])

  const submittingClosed = useMemo(() => {
    return getCentreUnitTask.result?.data.some((e) => e.closed)
  }, [getCentreUnitTask.result])

  const introContent = useMemo(() => {
    if (isCambridgeInternational()) {
      return ciIntroContent
    }

    return user && isHOC(user) ? ocrHoCIntroContent : ocrIntroContent
  }, [user])

  const qualificationFilter = useMemo<string>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )

    if (!qs.qualification) {
      return 'All Quals'
    }

    if (Array.isArray(qs.qualification)) {
      return qs.qualification[0]
    }

    return qs.qualification
  }, [location])

  const forbidden = useMemo(() => {
    if (!getCentreUnitTask.error) {
      return false
    }
    return isForbidden((getCentreUnitTask.error as AxiosError).response)
  }, [getCentreUnitTask.error])

  const centres = useMemo<CentreUnit[]>(() => {
    if (!getCentreUnitTask.result) {
      return []
    }

    return getCentreUnitTask.result.data.map((centreUnitDto) => ({
      ...centreUnitDto,
      percentageCompleted:
        (100 * centreUnitDto.gradedCandidates) / centreUnitDto.totalCandidates,
    }))
  }, [getCentreUnitTask.result])

  const qualReduce = useCallback(
    (
      func: (
        values: string[],
        centreUnit: CentreUnit,
        qual: Qualification
      ) => string[]
    ) =>
      centres.reduce(
        (quals: { [key: string]: string[] }, centre) =>
          centre.qualifications.reduce(
            (acc, qual) => ({
              ...acc,
              [qual.name]: func(acc[qual.name], centre, qual),
            }),
            quals
          ),
        {}
      ),
    [centres]
  )

  const qualCounts = useMemo(
    () =>
      qualReduce((values, { unitId }, _): string[] =>
        Array.from(new Set([...(values || []), unitId]))
      ),
    [qualReduce]
  )

  const notificationMessage = useMemo<any>(() => {
    if (user && isHOC(user)) {
      const submissionCount = (centres || []).filter(
        (x) => x.valuationStatus === ValuationStatus.SUBMITTED && !x.closed
      ).length
      if (submissionCount > 0) {
        const unitWord =
          submissionCount === 1
            ? getTextFromToken('syllabus')
            : getTextFromToken('syllabuses')
        return {
          icon: faBell,
          message: `You have ${submissionCount} ${unitWord} waiting for your approval`,
          className: 'text-white bg-success',
        }
      }
    }
    return undefined
  }, [user, centres])

  const uniqueQuals = useMemo(() => {
    return keys(qualCounts).sort()
  }, [qualCounts])

  const filtersCount = useMemo<{ [key: string]: number }>(
    () =>
      centres.reduce(
        (acc, curr) => ({
          ...acc,
          // @ts-ignore
          [curr.valuationStatus]: (acc[curr.valuationStatus] || 0) + 1,
          // @ts-ignore
          ALL: (acc.ALL || 0) + 1,
        }),
        {}
      ),
    [centres]
  )

  const [statusFilter, setStatusFilter] = useState('ALL')

  const filtered = useMemo(() => {
    let filtered = centres
      .filter(
        (x) =>
          qualificationFilter === 'All Quals' ||
          x.qualifications.find((qual) => qual.name === qualificationFilter)
      )
      .filter(
        (x) =>
          statusFilter === 'ALL' ||
          x.valuationStatus === statusFilter ||
          (statusFilter === ValuationStatus.OPENED &&
            !x.closed &&
            x.valuationStatus !== ValuationStatus.APPROVED)
      )

    if (searchFilter.length > 0) {
      filtered = filtered.filter(
        (x) =>
          (x.unitName &&
            x.unitName.toLowerCase().includes(searchFilter.toLowerCase())) ||
          (x.unitId &&
            x.unitId.toLowerCase().includes(searchFilter.toLowerCase()))
      )
    }
    return filtered
  }, [centres, qualificationFilter, searchFilter, statusFilter])

  const sorted = useMemo(() => {
    const list = [...filtered]
    list.sort(SORT_UNIT)
    return list
  }, [filtered])

  const latestDate = useMemo(
    () =>
      centres.reduce((acc, curr) => Math.max(acc, curr.lastUpdated || 0), 0),
    [centres]
  )

  const lastUpdatedString = useMemo(
    () => escapeSlashes(formatDate(new Date(latestDate), true, true), ' / '),
    [latestDate]
  )

  if (user && !isCAAdmin(user) && Date.now() < Number(config.available)) {
    return (
      <>
        <NotAvailable />
      </>
    )
  }

  return (
    <>
      <CAAdminBanner centreId={centreId} />

      {notificationMessage && (
        <Nav
          className={`font-weight-bold py-3 ${notificationMessage.className}`}
        >
          <Container className="d-flex align-items-center justify-content-between">
            <span className="d-inline-flex align-items-center">
              {notificationMessage.icon && (
                <FontAwesomeIcon
                  icon={notificationMessage.icon}
                  className="mr-3"
                />
              )}
              {notificationMessage.message}
            </span>
          </Container>
        </Nav>
      )}
      {submittingClosed && reopenedCount > 0 && (
        <Nav className="error-nav bg-primary text-white font-weight-bold py-3 top-border">
          <Container className="d-flex align-items-center justify-content-between">
            <span className="d-inline-flex align-items-center">
              <FontAwesomeIcon icon={faBell} className="mr-3" />
              {`You have ${reopenedCount} ${
                reopenedCount > 1
                  ? getTextFromToken('syllabuses')
                  : getTextFromToken('syllabus')
              } that ${reopenedCount > 1 ? 'are' : 'is'} open for submission`}
            </span>
            {showReopenedOnly && (
              <Container className="w-50 text-right px-0 mx-0">
                <Button
                  color="white"
                  className="text-primary"
                  onClick={() => {
                    setStatusFilter(ValuationStatus.OPENED)
                    setShowReopenedOnly(false)
                  }}
                >{`Show ${reopenedCount > 1 ? 'these' : 'that'} ${
                  reopenedCount > 1
                    ? getTextFromToken('syllabuses')
                    : getTextFromToken('syllabus')
                }`}</Button>
              </Container>
            )}
          </Container>
        </Nav>
      )}

      {submittingClosed && (
        <Nav className="error-nav bg-success text-white font-weight-bold py-4">
          <Container>
            <FontAwesomeIcon
              icon={faCalendarCheck}
              fixedWidth
              className="mr-3"
            />
            {isCambridgeInternational() && (
              <>
                {/** feature/ISPR-1517 hide ties and ranks **/}
                {/* <>The deadline for submitting grades and rank orders <u>has now passed.</u></> */}
                <>
                  The deadline for submitting grades <u>has now passed.</u>
                </>
                <div className="mt-2 cust-margin">
                  You can no longer submit data into the system. You can only
                  view and download. If you have any queries or questions please
                  contact us. Thank you.
                </div>
              </>
            )}
            {isOCR() && (
              <>
                <>
                  The deadline for submitting some qualifications{' '}
                  <u>has passed.</u>
                </>
                <ul className="mt-3 ml-4">
                  {/** feature/ISPR-1517 hide ties and ranks **/}
                  {/* <li>You can only submit data for the qualifications that are still open for centre assessment grades and rank orders.</li> */}
                  <li>
                    You can only submit data for the qualifications that are
                    still open for teacher assessed grades.
                  </li>
                  <li>
                    For others you can view and download. If you have any
                    queries please contact us. Thank you.
                  </li>
                </ul>
              </>
            )}
          </Container>
        </Nav>
      )}

      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row className="mb-1">
            <Col lg={12}>
              <div
                className="markdown-content"
                data-testid="centre-syllabus-intro-content"
              >
                <ReactMarkdown source={introContent} />
              </div>
            </Col>
            {isOCR() && user && isCentreUser(user) && (
              <Col className="text-right">
                <CentreGradeButton centreId={centreId} />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              {user && isHOC(user) ? (
                <strong>
                  If you need to make any changes after you have submitted your
                  grades, please contact us.
                </strong>
              ) : (
                <ul className="list-inline font-weight-bold ">
                  <li className="list-inline-item mr-5">
                    <span className="mr-2">&bull;</span> Download or upload
                    templates
                  </li>
                  <li className="list-inline-item mr-5">
                    {/* ** feature/ISPR-1517 hide ties and ranks ** */}
                    {/* <span className="mr-2" >&bull;</span> Enter or edit grades and rank order */}
                    <span className="mr-2">&bull;</span> Enter or edit grades
                  </li>
                  <li className="list-inline-item mr-3">
                    <span className="mr-2">&bull;</span> Send for review and
                    approval
                  </li>
                </ul>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt-5">
        {getCentreUnitTask.pending && (
          <SimpleMessage
            className="mb-5"
            icon={<Loading className="d-block mx-auto" />}
            title={`Retrieving ${getTextFromToken('syllabuses')}...`}
          />
        )}
        {forbidden && (
          <SimpleErrorMessage title="You do not have permission to submit for this centre" />
        )}
        {!forbidden && getCentreUnitTask.error && (
          <SimpleErrorMessage
            title={`Failed to load ${getTextFromToken(
              'syllabuses'
            )}, please refresh and if the problem persists contact your system administrator`}
            allowPageRefresh
          />
        )}
      </Container>
      <Container className="mt-5">
        {getCentreUnitTask.result && getCentreUnitTask.result.data.length > 0 && (
          <>
            <Row className="my-5">
              <Col lg={11}>
                <SearchBar
                  val={searchFilter}
                  placeholder={`Search for a ${getTextFromToken('unit')}`}
                  filterChanged={(newVal) => setSearchFilter(newVal)}
                  disableSearch={searchFilter.length < 5}
                  dataTestid="centre-syllabus-search-bar"
                />
              </Col>
              <Col className="d-flex justify-content-end opacity-59 font-weight-semi-bold text-right">
                {!!latestDate && (
                  <Label>
                    <div>Last updated on</div>
                    <div>{lastUpdatedString}</div>
                  </Label>
                )}
              </Col>
            </Row>
            <Row className="mb-5">
              <Col
                xs="auto"
                className="text-left font-weight-bold text-secondary"
              >
                <Label className="m-0">Filter by qualification</Label>
                <TabList className="mt-n1">
                  <Tab
                    className="mt-3"
                    autoSize
                    to={`?qualification=${encodeURIComponent('All Quals')}`}
                    data-testid="centre-syllabus-filter-qual-all"
                  >
                    <TabContentLabelWithCount
                      label="All Quals"
                      isActive={qualificationFilter === 'All Quals'}
                      className="h-100"
                    />
                  </Tab>
                  {uniqueQuals.map((qual, idx) => (
                    <Tab
                      className="mt-3"
                      autoSize
                      key={`qual-${idx}`}
                      to={`?qualification=${encodeURIComponent(qual)}`}
                      data-testid={`centre-syllabus-filter-qual-${qual}`}
                    >
                      <TabContentLabelWithCount
                        label={qual}
                        isActive={qualificationFilter === qual}
                        count={qualCounts[qual]?.length || 0}
                      />
                    </Tab>
                  ))}
                </TabList>
              </Col>
              <Col
                style={{ minWidth: '280px' }}
                className="d-inline-flex justify-content-end align-items-end pt-4"
              >
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="ml-3 p-0 label-dropdown d-inline-flex align-content-center"
                    data-testid="centre-syllabus-filter-dropdown-toggle"
                  >
                    Filtered by ({STATUS_FILTERS[statusFilter]})
                    <FontAwesomeIcon className="ml-2" icon={faSortDown} />
                  </DropdownToggle>

                  <DropdownMenu right className="mt-3">
                    {Object.keys(STATUS_FILTERS).map((key) => (
                      <DropdownItem
                        className={`text-left py-3 font-weight-bold mr-4
                        ${key === statusFilter ? 'bg-primary' : ''}`}
                        onClick={() => {
                          setStatusFilter(key)
                        }}
                        key={`filter-dropdown-${key}`}
                        data-testid={`centre-syllabus-filter-dropdown-${key}`}
                      >
                        {key === statusFilter && (
                          <FontAwesomeIcon icon={faCheck} />
                        )}
                        <span
                          className={key === statusFilter ? 'ml-2' : 'ml-4'}
                        >
                          {`${STATUS_FILTERS[key]} (${
                            key === 'opened'
                              ? reopenedCount
                              : filtersCount[key] || 0
                          })`}
                        </span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
            {sorted.map((qual, idx) => (
              <UnitProgress
                key={`sylprog-${idx}`}
                className="mb-45"
                onUpdate={() => {
                  getCentreUnitTask.start()
                }}
                {...qual}
              />
            ))}
          </>
        )}

        {user && getCentreUnitTask.result && sorted.length === 0 && (
          <SimpleMessage
            title={`No ${getTextFromToken('syllabuses')} ${
              centres.length === 0
                ? ' found for this centre'
                : ' match the filter/search'
            }`}
            message={
              isCAAdmin(user)
                ? 'Please check that you have entered the correct Centre ID.'
                : undefined
            }
          />
        )}
      </Container>
    </>
  )
}

export const CentreUnitPageWithRoute = withCentreId(CentreUnitPage)
export const CentreUnitPageWithRouteAndExpiration = withExpiration(
  CentreUnitPageWithRoute
)
