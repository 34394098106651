import React from 'react'
import { Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'
import { useAuth0 } from '../auth'
import { isAdmin, isCAAdmin, isOCR } from '../util'
import { LandingPage } from './landing-page/landing-page'
import HoldingPage from './holding-page/holding-page'
import { useAvailability } from '../common/hooks/use-availability'

export const HomePage: React.FC = (): JSX.Element => {
  const { user, currentCentre } = useAuth0()
  const { gradingAvailable } = useAvailability()

  if (user && isOCR()) {
    return <LandingPage />
  }

  if (user && isCAAdmin(user)) {
    return <Redirect to="centres" />
  }

  if (user && currentCentre) {
    if (gradingAvailable) {
      return <Redirect to={`centres/${currentCentre}`} />
    }
    if (isAdmin(user)) {
      return <Redirect to="/manage/teachers" />
    }
    return <HoldingPage />
  }
  return <Container></Container>
}
