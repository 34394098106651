import { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { isPast } from 'date-fns'

import { useConfig } from './use-remote-config'
import { useAuth0 } from '../../auth'
import { isCAAdmin } from '../../util'

export const useExpiryPage = () => {
  const { location } = useReactRouter()
  const { config } = useConfig()
  const { user, currentCentre } = useAuth0()

  const { captureAvailableTo, aamAvailableTo } = config

  const captureExpiryRoutes: RegExp[] = [
    new RegExp(`^\\/centres\\/${currentCentre}(\\/unit\\/\\d+){0,1}\\/?$`, 'i'),
    new RegExp(`^\\/manage\\/teachers\\/?$`, 'i'),
    new RegExp(`^\\/manage\\/signoff\\/?$`, 'i'),
  ]

  const aamExpiryRoutes: RegExp[] = [
    ...captureExpiryRoutes,
    new RegExp(`^\\/manage\\/aam\\/?$`, 'i'),
    new RegExp(`^\\/aam(\\/[\\w,(%20)\\s\\-]+)*\\/?$`, 'i'),
  ]

  const isExpiryRoute = useCallback(
    (routes: RegExp[]): boolean =>
      routes.some((route) => route.test(location.pathname)),
    [location]
  )

  const isCaAdmin: boolean = useMemo(() => !!user && isCAAdmin(user), [user])

  const aamAvailabilityExpired: boolean = useMemo(
    () =>
      isExpiryRoute(aamExpiryRoutes) &&
      !isCaAdmin &&
      !!aamAvailableTo &&
      isPast(aamAvailableTo),
    [aamAvailableTo, isExpiryRoute, isCaAdmin, aamExpiryRoutes]
  )

  const captureAvailabilityExpired: boolean = useMemo(
    () =>
      isExpiryRoute(captureExpiryRoutes) &&
      !isCaAdmin &&
      !!captureAvailableTo &&
      isPast(captureAvailableTo),
    [captureAvailableTo, isExpiryRoute, isCaAdmin, captureExpiryRoutes]
  )

  return [aamAvailabilityExpired, captureAvailabilityExpired]
}
