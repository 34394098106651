import React, { useRef, useMemo } from 'react'
import { Spinner } from 'reactstrap'
import { isUndefined, snakeCase } from 'lodash'
import { UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'

interface TabContentLabelWithCountProps {
  isActive?: boolean
  countPending?: boolean
  label: string
  count?: number
  outOfCount?: number
  errorMessage?: string
  className?: string
}

interface TargetMetProps {
  targetNotMetMessage?: string
}
export const TargetMetNotificaton: React.FC<
  TargetMetProps & React.HTMLAttributes<HTMLDivElement>
> = ({ targetNotMetMessage, className }): JSX.Element => {
  const targetNotMetIconRef = useRef<HTMLSpanElement>(null)
  return (
    <>
      <span
        aria-label="Target not met"
        className={`${className} fa-layers fa-fw  position-absolute font-smaller`}
        ref={targetNotMetIconRef}
        style={{
          right: '-.525rem',
          top: '-0.4rem',
        }}
      >
        <FontAwesomeIcon className="text-danger" icon={faCircle} />
        {targetNotMetMessage && targetNotMetIconRef.current && (
          <UncontrolledTooltip
            placement="top"
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            target={targetNotMetIconRef.current}
          >
            <div className="px-35 py-3 font-weight-bold">
              {targetNotMetMessage}
            </div>
          </UncontrolledTooltip>
        )}
      </span>
    </>
  )
}

export const TabContentLabelWithCount: React.FC<TabContentLabelWithCountProps> = ({
  isActive = false,
  label,
  countPending = false,
  outOfCount,
  errorMessage,
  count,
  className,
}): JSX.Element => {
  const syleClasses = useMemo(() => {
    if (isActive) {
      return 'bg-primary text-white'
    }
    if (count === 0) {
      return 'bg-white border text-dark'
    } else {
      return 'bg-lightButNotTooLight text-dark'
    }
  }, [isActive, count])

  return (
    <div
      data-testid={`tab-${snakeCase(label)}`}
      className={`${syleClasses} rounded font-weight-bold py-2 px-25 position-relative ${className}`}
    >
      {errorMessage && (
        <TargetMetNotificaton targetNotMetMessage={errorMessage} />
      )}
      <div data-testid="syllabus-filter" className="text-center">
        <div
          data-testid="syllabus-filter-grade"
          className="tab-content-label font-larger"
        >
          {label}
        </div>
        {!isUndefined(count) && (
          <div
            data-testid={`syllabus-filter-${label.toLowerCase()}`}
            className={`${
              !isActive ? 'text-secondary' : ''
            } h5 mb-0 font-weight-bold`}
          >
            {countPending && (
              <Spinner
                className="font-normal"
                color={isActive ? 'white' : 'secondary'}
                size="sm"
              />
            )}
            {!countPending && (
              <span>
                {count} {outOfCount && ` / ${outOfCount}`}{' '}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
