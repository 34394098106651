import React from 'react'

import { Button, Label, Spinner } from 'reactstrap'
import { ProjectModal } from '../../../modal/modal'
import { RsModalHeader } from '../../../modal/subcomponents/modal-header'
import { RsModalBody } from '../../../modal/subcomponents/modal-body'
import { RsModalFooter } from '../../../modal/subcomponents/modal-footer'

import { Title } from '../../../title/title'
import './bulk-x-grade-modal.scss'
import getTextFromToken from '../../../../tokenised-text'
import { isOCR } from '../../../../util'

interface IBulkSetXGradeModal {
  header: string
  subheader?: string
  ancillery?: string
  isOpen: boolean
  onClose: () => void
  candidatesWithoutGrade: number
  bulkSetXGrade: () => void
  showSpinner: boolean
}

export const BulkSetXGradeModal: React.FC<IBulkSetXGradeModal> = ({
  header,
  subheader = '',
  ancillery,
  onClose,
  isOpen,
  candidatesWithoutGrade,
  bulkSetXGrade,
  showSpinner,
}) => {
  return (
    <ProjectModal className="hoc-approval-modal" centered isOpen={isOpen}>
      <RsModalHeader
        toggle={onClose}
        className="hoc-approval-modal-header bg-white px-5 pt-5 pb-25 modal-md"
      >
        <Title title={header} subTitle={subheader} ancillery={ancillery} />
      </RsModalHeader>
      <RsModalBody className="hoc-approval-modal-body px-5">
        <Label className="font-larger mb-1 modal-margin font-weight-bold">
          Set list as complete
        </Label>
        <div className="mt-0 modal-margin">
          <div className="modal-body-text">
            <div>
              There are currently {candidatesWithoutGrade} candidates that have
              no grades added for this {getTextFromToken('unit')}. Do you have
              any more grades to add?
            </div>
            <div>
              {isOCR()
                ? 'Candidates with no grades will be set to ‘X’ to indicate you are not submitting a grade for this unit. You will be able to review the grades before you send to your Head of Centre for approval.'
                : 'Candidates with no grades will be set to ‘X’ to indicate they have no grades for submission, eg they are not completing the qualification yet. You will be able to review the grades before you send to your Head of Centre for approval.'
              }
            </div>
          </div>
        </div>
      </RsModalBody>
      <RsModalFooter className="hoc-approval-modal-footer px-5 pb-5">
        <Button
          onClick={onClose}
          color="e4"
          size="lg"
          className="footer-btn_cancel"
        >
          Cancel
        </Button>
        <Button color="primary" size="lg" onClick={() => bulkSetXGrade()}>
          {showSpinner ? (
            <Spinner className="mr-2" size="sm" />
          ) : (
            'Yes, set list as complete'
          )}
        </Button>
      </RsModalFooter>
    </ProjectModal>
  )
}
