import { useMemo } from 'react'
import { isFuture, isPast } from 'date-fns/esm'

import { useConfig } from './use-remote-config'
import { SystemAvailabilityEnum } from '../../types'
import { isCAAdmin } from '../../util'
import { useAuth0 } from '../../auth'

export const useAvailability = () => {
  const { config, systemAvailabilityConfigLoaded } = useConfig()
  const { user } = useAuth0()

  const {
    systemAvailability,
    captureAvailableFrom,
    captureAvailableTo,
    aamAvailableFrom,
    aamAvailableTo,
  } = config

  const isSystemAvailable = useMemo(
    () => systemAvailability === SystemAvailabilityEnum.AVAILABLE,
    [systemAvailability]
  )

  const aamManagementAvailable = useMemo(
    () =>
      isSystemAvailable &&
      ((aamAvailableFrom &&
        isPast(aamAvailableFrom) &&
        aamAvailableTo &&
        isFuture(aamAvailableTo)) ||
        !systemAvailabilityConfigLoaded),
    [
      isSystemAvailable,
      aamAvailableFrom,
      aamAvailableTo,
      systemAvailabilityConfigLoaded,
    ]
  )

  const userManagementAvailable = useMemo(
    () =>
      isSystemAvailable &&
      ((user && isCAAdmin(user)) ||
        (captureAvailableTo && isFuture(captureAvailableTo)) ||
        !systemAvailabilityConfigLoaded),
    [isSystemAvailable, captureAvailableTo, systemAvailabilityConfigLoaded]
  )

  const gradingAvailable = useMemo(
    () =>
      isSystemAvailable &&
      ((captureAvailableFrom &&
        captureAvailableTo &&
        isPast(captureAvailableFrom) &&
        isFuture(captureAvailableTo)) ||
        !systemAvailabilityConfigLoaded),
    [
      isSystemAvailable,
      captureAvailableFrom,
      captureAvailableTo,
      systemAvailabilityConfigLoaded,
    ]
  )

  return { aamManagementAvailable, userManagementAvailable, gradingAvailable }
}
