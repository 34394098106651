import axios, { AxiosResponse } from 'axios'
import React, { useMemo } from 'react'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { Col, Row, Button, Spinner } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownload } from '@fortawesome/pro-solid-svg-icons'
import { useDownloadFile } from '../../common/hooks/use-download-file'
import { PreSignedUploadUrl } from '../../types'
import { FileImageService } from '../../common/services/file-image.service'

interface IGuidanceDocDownload {
  fileName: string
  downloadLink: string
  dataTestIdSuffix: string
}

export const GuidanceDocDownload: React.FC<IGuidanceDocDownload> = ({
  fileName,
  downloadLink,
  dataTestIdSuffix,
}) => {
  const fileIcon = useMemo(
    () => FileImageService.getFileImageByFileName(fileName),
    [fileName]
  )

  const getPreassignedUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/${downloadLink}`,
    }
  }, [downloadLink])

  const getPreassignedUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getPreassignedUrl)

  const download = useDownloadFile({
    fileUrl: `${getPreassignedUrlTask.result?.data.url}`,
    fileName,
  })

  const handleDownload = () => {
    if (getPreassignedUrl) {
      getPreassignedUrlTask.start()
      return
    }
    download.start()
  }

  useAsyncRun(getPreassignedUrlTask.result && download)

  const inProgress = useMemo(() => {
    return (
      ((getPreassignedUrlTask.pending && getPreassignedUrlTask.started) ||
        (download.pending && download.started)) &&
      !download.result
    )
  }, [getPreassignedUrlTask, download])

  return (
    <Row className="guidance-doc__container mx-auto">
      <Col
        className="guidance-doc__file-name-container"
        data-testid={`guidance-doc-filename-${dataTestIdSuffix}`}
      >
        <FontAwesomeIcon
          icon={fileIcon}
          className="guidance-doc__file-name-icon"
        />
        {fileName}
      </Col>
      <Col>
        <Button
          className="guidance-doc__download-button"
          data-testid={`guidance-doc-download-button-${dataTestIdSuffix}`}
          onClick={handleDownload}
        >
          <span>
            {inProgress ? (
              <Spinner className="mr-2" size="sm" />
            ) : (
              <FontAwesomeIcon
                icon={faCloudDownload}
                className="guidance-doc__download-icon"
              />
            )}
            Download
          </span>
        </Button>
      </Col>
    </Row>
  )
}
