import React, { useEffect, useMemo } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { UnitCandidate } from '../../../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { compareTwoLowerCaseStrings } from '../../../util'

export const candidatesFiltering = (
  candidates: UnitCandidate[],
  filter: string
): string[] =>
  (filter === 'ALL'
    ? candidates
    : candidates.filter((it) => compareTwoLowerCaseStrings(it.grade, filter))
  ).map((it) => it.id)

export const buildGradingMap = (
  candidates: UnitCandidate[]
): { [key: string]: number } =>
  candidates
    .map((it) => it.grade)
    .filter((x) => x)
    .reduce(
      (acc, curr) => ({
        ...acc,
        // @ts-ignore
        [curr]: (acc[curr] || 0) + 1,
      }),
      {}
    )

interface ICandidatesFilter {
  gradingMap: { [key: string]: number }
  grading: string[]
  candidatesCount: number
  currentFilter: string
  updateFilter: (f: string) => void
  errorGrades?: string[]
}

export const CandidatesFilter: React.FC<ICandidatesFilter> = ({
  errorGrades,
  gradingMap,
  grading,
  candidatesCount,
  currentFilter,
  updateFilter,
}): JSX.Element => {
  const tabs = useMemo((): {
    key: string
    label: string
    count: string | number
  }[] => {
    return [
      {
        key: 'ALL',
        label: 'Predicted Grades',
        count: `${Object.values(gradingMap).reduce(
          (a, b) => a + b,
          0
        )} / ${candidatesCount}`,
      },
      ...grading.map((key) => ({
        key,
        label: key,
        count: gradingMap[key] || 0,
      })),
    ]
  }, [gradingMap, grading, candidatesCount])

  const activeIdx = useMemo(
    () => tabs.findIndex((it) => it.key === currentFilter),
    [tabs, currentFilter]
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    errorGrades?.length !== 0 ? errorGrades?.push('Predicted Grades') : null
  })

  return (
    <Row className="candidates-filter mb-4">
      {tabs.map(({ key, label, count }, idx) => (
        <Col
          key={`filter-col-${idx}`}
          lg={idx === 0 ? 4 : 2}
          md={idx === 0 ? 4 : 2}
          className="candidates-filter-col noselect"
        >
          {/*{errorGrades?.includes(label)z && errorGrades?.push('Centre Asssessment Grades')}*/}
          {errorGrades?.includes(label) && (
            <FontAwesomeIcon
              style={{ position: 'absolute', right: 0, top: -4 }}
              icon={faCircle}
              className="icon-red small"
              fixedWidth
            />
          )}
          <div
            className={`candidates-filter-tab py-1 ${
              count === 0 ? 'empty' : ''
            } ${activeIdx === idx ? 'active' : ''}`}
            onClick={() => (idx === 0 || count > 0) && updateFilter(key)}
          >
            <Label className="candidates-filter-label">{label}</Label>
            <Label className="candidates-filter-count">{count}</Label>
          </div>
        </Col>
      ))}
    </Row>
  )
}
