import {
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  IdToken,
  PopupLoginOptions,
  RedirectLoginResult,
  getIdTokenClaimsOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js'
import { DataSource } from './common/enum/data-source'
import { FileType } from './common/enum/file-type.enum'

export enum ValuationStatus {
  NOT_STARTED = 'not_started',
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  OPENED = 'opened',
}

export interface UnitCandidate {
  id: string
  centreUnitId: string
  candidateNumber: string
  name: string
  dob: string
  grade?: string
  // ** feature/ISPR-1517 hide ties and ranks **
  // rank?: number
  allowedGrades: string[]
  lastUpdated?: string
}

export interface CentreUnitDto {
  closed: boolean
  id: string
  centreId: string
  unitName: string
  unitId: string
  qualifications: Qualification[]
  lastUpdated?: number
  totalCandidates: number
  gradedCandidates: number
  valuationStatus: ValuationStatus
  percentageCompleted?: number
  tiesAllowed?: boolean
  dataSource: DataSource
  deadline: number
  extendedDeadline: number
}

export interface Qualification {
  code: string
  name: string
}

export interface CentreUnit extends CentreUnitDto {
  percentageCompleted?: number
}

export interface PathParam {
  id: string
  unitId?: string
}

export interface SpecificationFile {
  bucket: string
  absolutePath: string
  fileName: string
  fileType: FileType
  size: string
  creationDate: string
}

export interface ManagedUser {
  userId?: string
  name: string
  surname: string
  email: string
  role?: string
  confirmed?: boolean
  centres?: string[]
}

export interface User {
  email: string
  centres: string[]
  user_id: string
  sub: string
  picture: string
  name: string
  nickname: string
  roles: string[]
  email_verified: boolean
  isPlaceholder?: boolean
}
export interface AuthCallback {
  onRedirectCallback?: (appState: any) => void
}
export interface AuthContextProps {
  isAuthenticated: boolean
  user?: User
  currentCentre?: string
  loading?: boolean
  loginWithPopup(o?: PopupLoginOptions): Promise<void>
  handleRedirectCallback(): Promise<RedirectLoginResult>
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
  logout(o?: LogoutOptions): void
  setCurrentCentre(cenreId: string): void
}

export interface AppState {
  targetUrl: string
}
export interface AuthConfig {
  domain: string
  client_id: string
  audience?: string
  scope?: string
  role_url: string
  centre_url: string
}

export interface InstructionMaterialDTO {
  materials: string[]
}

export interface LocalConfig {
  available?: number
  auth0Config?: AuthConfig
  siteEnabled?: boolean
}

export interface SystemAvailabilityConfigDTO {
  captureAvailableFrom: number
  captureAvailableTo: number
  aamAvailableFrom: number
  aamAvailableTo: number
}

export enum SystemAvailabilityEnum {
  AVAILABLE = 'AVAILABLE',
  CAPTURE_ERROR = 'CAPTURE_ERROR',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  CLOSED = 'CLOSED',
}

export interface SystemAvailabilityConfig {
  systemAvailability?: SystemAvailabilityEnum
  captureAvailableFrom?: Date
  captureAvailableTo?: Date
  aamAvailableFrom?: Date
  aamAvailableTo?: Date
}

export interface Config extends LocalConfig, SystemAvailabilityConfig {}

export interface ConfigState {
  mainConfigLoaded: boolean
  systemAvailabilityConfigLoaded: boolean
  config: Config
}
export interface PreSignedUploadUrl {
  url: string
  uuid: string
}
export interface UploadStatus {
  validationErrors: ValidationError[]
  status: string
  id: string
  updatedItems: number
  aggregatedErrors: { [key: string]: number }
}
export interface ValidationError {
  errorCode: string
  errorMessage: string
  fieldName: string
  fieldValue: string
  uniqueId: string
}
export interface MissingData {
  missingPredicted: number
  // ** feature/ISPR-1517 hide ties and ranks **
  // missingRank: number
  // tiedRank: number
}
