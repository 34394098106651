import React, { useMemo } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Label,
  Progress,
  Row,
} from 'reactstrap'

import { Link } from 'react-router-dom'
import { DownloadTemplateButton } from './components/download-template-button'
import { CentreUnit, ValuationStatus } from '../../types'
import { Title } from '../title/title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faClock,
  faExclamationTriangle,
  faEye,
} from '@fortawesome/pro-regular-svg-icons'
import { faPaperPlane, faPen } from '@fortawesome/pro-solid-svg-icons'
import {
  isAdmin,
  isCAAdmin,
  isHOC,
  isTeacher,
  unitInProgress,
  isOCR,
} from '../../util'
import { useAuth0 } from '../../auth'
import { UnitReopenButton } from '../unit-reopen-button/unit-reopen-button'
import { getSubTitle } from '../../common/services/centre-unit-service'
import { DeadlineButton } from './deadline-button/deadline-button'

interface Props {
  onUpdate?: () => void
}

export const UnitProgress: React.FC<
  Props & CentreUnit & React.HTMLAttributes<HTMLDivElement>
> = ({
  className,
  id,
  unitName,
  unitId,
  qualifications,
  totalCandidates,
  gradedCandidates,
  centreId,
  valuationStatus,
  percentageCompleted,
  closed,
  dataSource,
  deadline,
  onUpdate,
}): JSX.Element => {
  const inProgress = unitInProgress(valuationStatus)
  const is = useMemo(() => (status: string) => valuationStatus === status, [
    valuationStatus,
  ])
  const { user } = useAuth0()

  const unitTemplateFileName = useMemo(() => `${centreId}_${unitId}.csv`, [
    centreId,
    unitId,
  ])

  const title = useMemo(() => unitName || unitId, [unitName, unitId])
  const subTitle = useMemo(() => getSubTitle(unitName, unitId), [
    unitName,
    unitId,
  ])

  return (
    <>
      <Link
        className="no-text-hover"
        to={`/centres/${centreId}/unit/${id}`}
        data-testid={`syllabus-progress-${id}`}
      >
        <Card
          className={`${className} project-row transition-none`}
          data-testid="syllabus-card"
        >
          <CardBody
            className={`p-42 ${inProgress ? '' : 'bg-lightButNotTooLight'}`}
          >
            {isOCR() ? (
              <Row className="align-items-center justify-content-between">
                <Col>
                  <Title title={title} subTitle={subTitle} />
                </Col>
                <Col xs="auto" className="text-right px-3">
                  <span
                    className={`d-md-inline-block text-secondary font-weight-semi-bold`}
                  >
                    <span
                      data-testid={`candidate-progress-${id}`}
                      className="mb-0 font-larger"
                    >
                      {gradedCandidates}
                      {' / '}
                      {totalCandidates}
                    </span>
                    <span>{' candidates completed'}</span>
                  </span>
                </Col>
                {is(ValuationStatus.SUBMITTED) && closed && (
                  <Col xs="auto" className="text-right px-3">
                    <div
                      className="syllabus-progress-notification text-uppercase border border-2 rounded
                        font-weight-bold px-3 py-2 text-primary border-black37"
                      data-testid={`syllabus-progress-status-${id}`}
                    >
                      <Label className="black-color mb-0">
                        <FontAwesomeIcon
                          className="mr-2"
                          size="sm"
                          icon={faExclamationTriangle}
                        />
                        <span>INCOMPLETE</span>
                      </Label>
                    </div>
                  </Col>
                )}
                {inProgress && (
                  <Col xs="auto" className="text-right px-3">
                    <div
                      className={`syllabus-progress-notification text-uppercase border border-2 rounded
                        font-weight-bold px-3 py-2 text-primary
                    ${
                      is(ValuationStatus.NOT_STARTED)
                        ? 'border-black37'
                        : 'border-primary37'
                    }
                    `}
                      data-testid={`syllabus-progress-status-${id}`}
                    >
                      {is(ValuationStatus.NOT_STARTED) && !closed && (
                        <span className="text-secondary">NOT STARTED</span>
                      )}
                      {is(ValuationStatus.INCOMPLETE) && !closed && (
                        <span>IN PROGRESS</span>
                      )}
                      {is(ValuationStatus.COMPLETE) && !closed && (
                        <span>COMPLETED</span>
                      )}
                      {inProgress && closed && (
                        <Label className="black-color mb-0">
                          <FontAwesomeIcon
                            className="mr-2"
                            size="sm"
                            icon={faExclamationTriangle}
                          />
                          <span>INCOMPLETE</span>
                        </Label>
                      )}
                    </div>
                  </Col>
                )}
                {user && (isAdmin(user) || isTeacher(user) || isHOC(user)) && (
                  <Col xs="auto" className="text-right">
                    <DownloadTemplateButton
                      centreId={centreId}
                      id={id}
                      fileName={unitTemplateFileName}
                    />
                  </Col>
                )}
                {!closed &&
                  user &&
                  isCAAdmin(user) &&
                  is(ValuationStatus.APPROVED) && (
                    <Col xs="auto" className="text-right">
                      <UnitReopenButton
                        btnText="Status"
                        centreId={centreId}
                        header={title}
                        subheader={subTitle}
                        ancillery={`${totalCandidates} candidates`}
                        unitId={id}
                        gradedCandidates={gradedCandidates}
                        totalCandidates={totalCandidates}
                        unitReopened={() => onUpdate && onUpdate()}
                      />
                    </Col>
                  )}
                {user && isCAAdmin(user) && (
                  <DeadlineButton
                    disabled={!closed}
                    header={title}
                    subheader={subTitle}
                    ancillery={`${totalCandidates} candidates`}
                    centreId={centreId}
                    unitId={id}
                    onUpdate={() => onUpdate && onUpdate()}
                  />
                )}
                {inProgress && !is(ValuationStatus.SUBMITTED) && (
                  <Col xs="auto" className="text-right px-3">
                    {user &&
                    (isTeacher(user) || isHOC(user) || isAdmin(user)) &&
                    !closed ? (
                      <Button color="success" className="text-uppercase">
                        <FontAwesomeIcon
                          icon={
                            is(ValuationStatus.COMPLETE) ? faPaperPlane : faPen
                          }
                          className="mr-2 font-normal"
                        />
                        <span className="font-normal">
                          {is(ValuationStatus.COMPLETE) ? 'Send' : 'Edit'}
                        </span>
                      </Button>
                    ) : (
                      <Button color="success" className="text-uppercase">
                        <FontAwesomeIcon
                          icon={faEye}
                          className="mr-2 font-normal"
                        />
                        <span className="font-normal">View</span>
                      </Button>
                    )}
                  </Col>
                )}
                {((user && isHOC(user) && is(ValuationStatus.SUBMITTED)) ||
                  is(ValuationStatus.APPROVED)) && (
                  <Col xs="auto" className="text-right px-3">
                    <div className="syllabus-progress-notification text-uppercase rounded font-weight-bold px-3 py-2 text-white bg-success">
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={
                          is(ValuationStatus.SUBMITTED) ? faEye : faCheckCircle
                        }
                      />
                      <span>
                        {is(ValuationStatus.SUBMITTED)
                          ? closed
                            ? 'VIEW'
                            : 'VIEW AND APPROVE'
                          : 'APPROVED AND SUBMITTED'}
                      </span>
                    </div>
                  </Col>
                )}
                {!(user && isHOC(user)) && is(ValuationStatus.SUBMITTED) && (
                  <Col xs="auto" className="text-right px-3">
                    <div className="syllabus-progress-notification text-uppercase rounded font-weight-bold px-3 py-2 text-white bg-primary">
                      <FontAwesomeIcon className="mr-2" icon={faClock} />
                      AWAITING APPROVAL
                    </div>
                  </Col>
                )}
              </Row>
            ) : (
              [
                <Row
                  key="syllabus-progress-ci-row-one"
                  className="align-items-center justify-content-between mb-4"
                >
                  <Col>
                    <Title title={title} subTitle={subTitle} />
                  </Col>
                  <Col xs="auto" className="text-right">
                    <span
                      className={`d-md-inline-block text-secondary font-weight-semi-bold`}
                    >
                      <span
                        data-testid={`candidate-progress-${id}`}
                        className="mb-0 font-larger"
                      >
                        {gradedCandidates}
                        {' / '}
                        {totalCandidates}
                      </span>
                      <span>{' candidates completed'}</span>
                    </span>
                  </Col>
                </Row>,
                <Row
                  key="syllabus-progress-ci-row-two"
                  className="align-items-center justify-content-start"
                >
                  {is(ValuationStatus.SUBMITTED) && closed && (
                    <Col xs="auto" className="text-right">
                      <div
                        className="syllabus-progress-notification text-uppercase border border-2 rounded
                          font-weight-bold px-3 py-2 text-primary border-black37"
                        data-testid={`syllabus-progress-status-${id}`}
                      >
                        <Label className="black-color mb-0">
                          <FontAwesomeIcon
                            className="mr-2"
                            size="sm"
                            icon={faExclamationTriangle}
                          />
                          <span>INCOMPLETE</span>
                        </Label>
                      </div>
                    </Col>
                  )}
                  {inProgress && (
                    <Col xs="auto" className="text-right">
                      <div
                        className={`syllabus-progress-notification text-uppercase border border-2 rounded
                        font-weight-bold px-3 py-2 text-primary
                        ${
                          is(ValuationStatus.NOT_STARTED)
                            ? 'border-black37'
                            : 'border-primary37'
                        }
                        `}
                        data-testid={`syllabus-progress-status-${id}`}
                      >
                        {is(ValuationStatus.NOT_STARTED) && !closed && (
                          <span className="text-secondary">NOT STARTED</span>
                        )}
                        {is(ValuationStatus.INCOMPLETE) && !closed && (
                          <span>IN PROGRESS</span>
                        )}
                        {is(ValuationStatus.COMPLETE) && !closed && (
                          <span>COMPLETED</span>
                        )}
                        {inProgress && closed && (
                          <Label className="black-color mb-0">
                            <FontAwesomeIcon
                              className="mr-2"
                              size="sm"
                              icon={faExclamationTriangle}
                            />
                            <span>INCOMPLETE</span>
                          </Label>
                        )}
                      </div>
                    </Col>
                  )}
                  {user && (isAdmin(user) || isTeacher(user) || isHOC(user)) && (
                    <Col xs="auto" className="text-right">
                      <DownloadTemplateButton
                        centreId={centreId}
                        id={id}
                        fileName={unitTemplateFileName}
                      />
                    </Col>
                  )}
                  {!closed &&
                    user &&
                    isCAAdmin(user) &&
                    is(ValuationStatus.APPROVED) && (
                      <Col xs="auto" className="text-right">
                        <UnitReopenButton
                          btnText="Status"
                          centreId={centreId}
                          header={title}
                          subheader={subTitle}
                          ancillery={`${totalCandidates} candidates`}
                          unitId={id}
                          gradedCandidates={gradedCandidates}
                          totalCandidates={totalCandidates}
                          unitReopened={() => onUpdate && onUpdate()}
                        />
                      </Col>
                    )}
                  {user && isCAAdmin(user) && (
                    <DeadlineButton
                      disabled={!closed}
                      header={title}
                      subheader={subTitle}
                      ancillery={`${totalCandidates} candidates`}
                      centreId={centreId}
                      unitId={id}
                      onUpdate={() => onUpdate && onUpdate()}
                    />
                  )}
                  {inProgress && !is(ValuationStatus.SUBMITTED) && (
                    <Col xs="auto" className="text-right">
                      {user &&
                      (isTeacher(user) || isHOC(user) || isAdmin(user)) &&
                      !closed ? (
                        <Button color="success" className="text-uppercase">
                          <FontAwesomeIcon
                            icon={
                              is(ValuationStatus.COMPLETE)
                                ? faPaperPlane
                                : faPen
                            }
                            className="mr-2 font-normal"
                          />
                          <span className="font-normal">
                            {is(ValuationStatus.COMPLETE) ? 'Send' : 'Edit'}
                          </span>
                        </Button>
                      ) : (
                        <Button color="success" className="text-uppercase">
                          <FontAwesomeIcon
                            icon={faEye}
                            className="mr-2 font-normal"
                          />
                          <span className="font-normal">View</span>
                        </Button>
                      )}
                    </Col>
                  )}
                  {((user && isHOC(user) && is(ValuationStatus.SUBMITTED)) ||
                    is(ValuationStatus.APPROVED)) && (
                    <Col xs="auto" className="text-right">
                      <div className="syllabus-progress-notification text-uppercase rounded font-weight-bold px-3 py-2 text-white bg-success">
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={
                            is(ValuationStatus.SUBMITTED)
                              ? faEye
                              : faCheckCircle
                          }
                        />
                        <span>
                          {is(ValuationStatus.SUBMITTED)
                            ? closed
                              ? 'VIEW'
                              : 'VIEW AND APPROVE'
                            : 'APPROVED AND SUBMITTED'}
                        </span>
                      </div>
                    </Col>
                  )}
                  {!(user && isHOC(user)) && is(ValuationStatus.SUBMITTED) && (
                    <Col xs="auto" className="text-right">
                      <div className="syllabus-progress-notification text-uppercase rounded font-weight-bold px-3 py-2 text-white bg-primary">
                        <FontAwesomeIcon className="mr-2" icon={faClock} />
                        AWAITING APPROVAL
                      </div>
                    </Col>
                  )}
                </Row>,
              ]
            )}
          </CardBody>
          {(is(ValuationStatus.INCOMPLETE) || is(ValuationStatus.COMPLETE)) && (
            <CardFooter className="p-0 border-0">
              <Progress
                className="progress-slim-1 rounded-top-0 rounded-bottom-sm-down"
                max={100 || 0}
                value={percentageCompleted || 0}
              />
            </CardFooter>
          )}
        </Card>
      </Link>
    </>
  )
}
