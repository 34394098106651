import React, {FC, useContext, useEffect, useMemo, useState} from 'react'
import axios, {AxiosResponse} from 'axios'
import {useAsyncRun, useAsyncTaskAxios} from 'react-hooks-async'
import {
  ConfigState,
  LocalConfig,
  SystemAvailabilityConfig,
  SystemAvailabilityConfigDTO,
  SystemAvailabilityEnum,
} from '../../types'

export const ConfigContext = React.createContext<ConfigState>({
  mainConfigLoaded: true,
  systemAvailabilityConfigLoaded: false,
  config: {},
})

const parseSystemAvailableEnum = (value: any) => {
  return SystemAvailabilityEnum[value as keyof typeof SystemAvailabilityEnum]
      || SystemAvailabilityEnum.SYSTEM_ERROR;
}

const LoadSystemAvailabilityConfigContext = React.createContext<() => void>(
  () => null
)

export const useConfig = (): ConfigState => useContext(ConfigContext)
export const useLoadSystemAvailabilityConfig = () =>
  useContext(LoadSystemAvailabilityConfigContext)

export const ConfigProvider: FC = ({ children }): JSX.Element => {
  const getConfigMemo = useMemo(() => ({
    url:
      process.env.REACT_APP_CONFIGPATH ||
      `/config.json?${new Date().getTime()}`,
  }), [])

  const getConfigTask = useAsyncTaskAxios<AxiosResponse<LocalConfig>>(
    axios,
    getConfigMemo
  )
  useAsyncRun(getConfigTask)

  const [config, setConfig] = useState<ConfigState>({
    mainConfigLoaded: false,
    systemAvailabilityConfigLoaded: false,
    config: {},
  })

  useEffect(() => {
    if (getConfigTask.result) {
      setConfig((prev) => ({
        ...prev,
        mainConfigLoaded: true,
        config: { ...prev.config, ...getConfigTask.result.data },
      }))
    }
  }, [getConfigTask.result])

  const getPublicSystemAvailability = useMemo(() => ({
    url: process.env.REACT_APP_APIDOMAIN + '/public-system-availability',
  }), [])

  const getPublicSystemAvailabilityTask = useAsyncTaskAxios<
      AxiosResponse<string>
      >(axios, getPublicSystemAvailability)

  useAsyncRun(getPublicSystemAvailabilityTask)

  useEffect(() => {
    if (getPublicSystemAvailabilityTask.result || getPublicSystemAvailabilityTask.error) {
      setConfig((prev) => ({
        ...prev,
        config: {
          ...prev.config,
          systemAvailability: parseSystemAvailableEnum(getPublicSystemAvailabilityTask?.result?.data)
        },
      }))
    }
  }, [getPublicSystemAvailabilityTask.result, getPublicSystemAvailabilityTask.error])


  const getSystemAvailabilityConfig = useMemo(() => ({
    url: process.env.REACT_APP_APIDOMAIN + '/system-availability-config',
  }), [])

  const getSystemAvailabilityConfigTask = useAsyncTaskAxios<
    AxiosResponse<SystemAvailabilityConfigDTO>
  >(axios, getSystemAvailabilityConfig)

  const loadSystemAvailabilityConfig = () => {
    getSystemAvailabilityConfigTask.start()
  }

  useEffect(() => {
    if (getSystemAvailabilityConfigTask.result) {
      const systemAvailabilityConvertedConfigs = Object.entries(
        getSystemAvailabilityConfigTask.result.data
      ).reduce<SystemAvailabilityConfig>(
        (acc, [key, val]) => ({
          ...acc,
          [key]: new Date(val),
        }),
        {} as SystemAvailabilityConfig
      )

      setConfig((prev) => ({
        ...prev,
        systemAvailabilityConfigLoaded: true,
        config: { ...prev.config, ...systemAvailabilityConvertedConfigs },
      }))
    }
  }, [getSystemAvailabilityConfigTask.result])

  return (
    <ConfigContext.Provider value={config}>
      <LoadSystemAvailabilityConfigContext.Provider
        value={loadSystemAvailabilityConfig}
      >
        {getConfigTask.result && children}
      </LoadSystemAvailabilityConfigContext.Provider>
    </ConfigContext.Provider>
  )
}
