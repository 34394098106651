import React, { FC, useEffect, useMemo, useState } from 'react'
import { BackButtonProvider } from '../common/context/back-button.context'
import axios from 'axios'
import { SpecificationFiles } from './specification-files/specification-files'
import { SpecificationToQualification } from './specifications-to-qualification/specifications-to-qualification'
import { SubjectPage } from './subject-page/subject-page'
import { Switch } from 'react-router'
import { NavBar } from './navbar'
import { HomePage } from './home'
import PrivateRoute from './private-route/private-route'
import { CentreUnitPageWithRouteAndExpiration } from './centre-syllabus-page/centre-unit-page'
import {
  ManageTeachersPageWithExpiration,
  ManageTeachersPageWithRouteAndExpiration,
} from './pages/manage-teachers'
import {
  ManageAAMPageWithExpiration,
  ManageAAMPageWithRouteAndExpiration,
} from './pages/manage-aam'

import {
  ManageSignOffPageWithExpiration,
  ManageSignOffPageWithRouteAndExpiration,
} from './pages/manage-sign-off'

import { isCAAdmin, isOCR, ROLES } from '../util'
import { useAuth0 } from '../auth'
import { UnitCodes } from './unit-codes/unit-codes'

import {
  useConfig,
  useLoadSystemAvailabilityConfig,
} from '../common/hooks/use-remote-config'
import HoldingPage from './holding-page/holding-page'
import ServerErrorHoldingPage from './holding-page/server-error-holding-page'
import SystemClosedHoldingPage from './holding-page/system-closed-holding-page'
import { HelpAndContact } from './help-and-contact'
import { useTracking } from '../common/hooks/use-tracking'
import { ManageAdminsPageWithRouteAndExpiration } from './pages/manage-admins'
import { AdditionalAssessmentMaterial } from './additional-assessment-material/additional-assessment-material'
import { CambridgeAdminControls } from './ca-admin-controls/ca-admin-controls'
import Route from './route'
import { useExpiryPage } from '../common/hooks/use-expiry-page'
import { ExpiryHoldingPage } from './holding-page/expiry-holding-page'
import { NotFound } from './not-found'
import { useAvailability } from '../common/hooks/use-availability'
import { SystemAvailabilityEnum } from '../types'
import { Redirect } from 'react-router-dom'

export const RoutesWithHeader: FC = (): JSX.Element => {
  useTracking(process.env.REACT_APP_GA_TRACKING_CODE)

  const { user, currentCentre, isAuthenticated } = useAuth0()
  const { config } = useConfig()
  const loadSystemAvailabilityConfig = useLoadSystemAvailabilityConfig()

  const [aamAvailabilityExpired, captureAvailabilityExpired] = useExpiryPage()
  const [serverError, setServerError] = useState<any | null>(null)

  const {
    aamManagementAvailable,
    userManagementAvailable,
    gradingAvailable,
  } = useAvailability()

  useEffect(() => {
    axios.interceptors.response.use(undefined, function (error) {
      if (`${error?.response?.status}`.startsWith('5')) {
        // 5xx error code
        setServerError(error)
      }
      return Promise.reject(error)
    })
  }, [])

  useEffect(() => {
    if (config.systemAvailability === SystemAvailabilityEnum.SYSTEM_ERROR) {
      setServerError(SystemAvailabilityEnum.SYSTEM_ERROR)
    }
  }, [config.systemAvailability])

  const rules = useMemo(() => {
    const aam = (isOCR() && user && isCAAdmin(user)) || aamManagementAvailable

    const centres = gradingAvailable

    const manage = userManagementAvailable

    return {
      aam,
      manageAAM: isOCR() || !isAuthenticated,
      centres,
      manage,
    }
  }, [
    isAuthenticated,
    user,
    aamManagementAvailable,
    userManagementAvailable,
    gradingAvailable,
  ])

  useEffect(() => {
    if (isAuthenticated) {
      loadSystemAvailabilityConfig()
    }
  }, [isAuthenticated])

  const renderedContent = useMemo(() => {
    if (serverError) {
      return <ServerErrorHoldingPage />
    }
    if (!config.siteEnabled) {
      return <HoldingPage />
    }
    if (!config.systemAvailability) {
      return null
    }
    if (config.systemAvailability === SystemAvailabilityEnum.CAPTURE_ERROR) {
      return (
        <PrivateRoute>
          <ServerErrorHoldingPage />;
        </PrivateRoute>
      )
    }
    if (config.systemAvailability === SystemAvailabilityEnum.CLOSED) {
      return <SystemClosedHoldingPage />
    }
    return (
      <Switch>
        <Route exact path="/">
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        </Route>

        {rules.aam && [
          <Route key="aam-1" exact path="/aam">
            <PrivateRoute>{<AdditionalAssessmentMaterial />}</PrivateRoute>
          </Route>,
          <Route
            key="aam-2"
            exact
            path="/aam/:qualification"
            render={(props) => (
              <PrivateRoute>
                <SpecificationToQualification
                  qualification={props.match.params.qualification}
                />
              </PrivateRoute>
            )}
          />,
          <Route
            key="aam-3"
            exact
            path="/aam/:qualification/:specification"
            render={(props) => (
              <PrivateRoute>
                <UnitCodes
                  qualification={props.match.params.qualification}
                  //todo need refactor
                  specification={props.match.params.specification.replaceAll(
                    '%2F',
                    '/'
                  )}
                />
              </PrivateRoute>
            )}
          />,
          <Route
            key="aam-4"
            exact
            path="/aam/:qualification/:specification/:unitCode"
            render={(props) => (
              <PrivateRoute>
                <SpecificationFiles
                  qualification={props.match.params.qualification}
                  //todo need refactor
                  specification={props.match.params.specification.replaceAll(
                    '%2F',
                    '/'
                  )}
                  unitCode={props.match.params.unitCode.replaceAll('%2F', '/')}
                />
              </PrivateRoute>
            )}
          />,
        ]}

        <Route exact path="/centres">
          <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
            <CambridgeAdminControls />
          </PrivateRoute>
        </Route>
        {!rules.centres && user && isCAAdmin(user) && (
          <Redirect
            exact
            from="/centres/:id"
            to="/centres/:id/manage/teachers"
          />
        )}
        {rules.centres && [
          <Route key="centres-1" exact path="/centres/:id">
            <PrivateRoute>
              <CentreUnitPageWithRouteAndExpiration
                isExpired={captureAvailabilityExpired}
              />
            </PrivateRoute>
          </Route>,
          <Route key="centres-2" exact path="/centres/:id/unit/:unitId">
            <PrivateRoute>
              <SubjectPage />
            </PrivateRoute>
          </Route>,
        ]}

        {rules.manageAAM && [
          <Route key="manageaam-1" exact path="/manage/aam">
            <PrivateRoute roles={[ROLES.Admin]}>
              {currentCentre && (
                <ManageAAMPageWithExpiration
                  centreId={currentCentre}
                  isExpired={aamAvailabilityExpired}
                />
              )}
            </PrivateRoute>
          </Route>,
          <Route key="manageaam-2" exact path="/centres/:id/manage/aam">
            <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
              {user && (
                <ManageAAMPageWithRouteAndExpiration
                  isExpired={aamAvailabilityExpired}
                />
              )}
            </PrivateRoute>
          </Route>,
        ]}

        {rules.manage && [
          <Route exact path="/manage/teachers">
            <PrivateRoute roles={[ROLES.Admin]}>
              {currentCentre && (
                <ManageTeachersPageWithExpiration
                  centreId={currentCentre}
                  isExpired={captureAvailabilityExpired}
                />
              )}
            </PrivateRoute>
          </Route>,
          <Route exact path="/manage/signoff">
            <PrivateRoute roles={[ROLES.Admin]}>
              {currentCentre && (
                <ManageSignOffPageWithExpiration
                  centreId={currentCentre}
                  isExpired={captureAvailabilityExpired}
                />
              )}
            </PrivateRoute>
          </Route>,

          <Route exact path="/centres/:id/manage/teachers">
            <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
              {user && (
                <ManageTeachersPageWithRouteAndExpiration
                  isExpired={captureAvailabilityExpired}
                />
              )}
            </PrivateRoute>
          </Route>,
          <Route exact path="/centres/:id/manage/signoff">
            <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
              {user && (
                <ManageSignOffPageWithRouteAndExpiration
                  isExpired={captureAvailabilityExpired}
                />
              )}
            </PrivateRoute>
          </Route>,
          <Route exact path="/centres/:id/manage/admins">
            <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
              {user && (
                <ManageAdminsPageWithRouteAndExpiration
                  isExpired={captureAvailabilityExpired}
                />
              )}
            </PrivateRoute>
          </Route>,
        ]}

        <Route exact path="/help-and-contact">
          <PrivateRoute>
            <HelpAndContact />
          </PrivateRoute>
        </Route>

        {(aamAvailabilityExpired || captureAvailabilityExpired) && (
          <Route component={ExpiryHoldingPage} />
        )}

        <Route component={NotFound} />
      </Switch>
    )
  }, [
    aamAvailabilityExpired,
    captureAvailabilityExpired,
    config.siteEnabled,
    config.systemAvailability,
    currentCentre,
    rules.aam,
    rules.centres,
    rules.manage,
    rules.manageAAM,
    serverError,
    user,
  ])

  return (
    <div style={{ marginBottom: '5rem' }}>
      <BackButtonProvider>
        <NavBar />
        {renderedContent}
      </BackButtonProvider>
    </div>
  )
}
