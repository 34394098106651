import { faCaretDown, faCheck } from '@fortawesome/pro-solid-svg-icons'
import axios, { AxiosResponse } from 'axios'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useAsyncTaskAxios } from 'react-hooks-async'
import { useHistory } from 'react-router-dom'
import { PagePagination } from '../../common/components/page-pagination'
import { SpecificationFile } from '../../types'
import { SearchBar } from '../search-bar/search-bar'
import { FileRow } from './file-row/file-row'
import { useBackButtonUpdate } from '../../common/context/back-button.context'
import './specification-files.scss'
import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { Loading } from '../loading'
import { FilePathService } from '../../common/services/file-path.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { escapeSlashes } from '../../util'

interface Props {
  qualification: string
  specification: string
  unitCode: string
}

export const SpecificationFiles: FC<Props> = ({
  qualification,
  specification,
  unitCode,
}) => {
  const availablePageLimit = [20, 50, 100]

  const [searchInput, setSearchInput] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>()
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const history = useHistory()

  const [isPageLimitDropdownOpen, setIsPageLimitDropdownOpen] = useState(false)
  const toggleDropDown = () =>
    setIsPageLimitDropdownOpen(
      (isPageLimitDropdownOpen) => !isPageLimitDropdownOpen
    )
  const calculateOffset = (page: number) => limit * (page - 1)

  const getFileToSpecificationsUrl = (search: string, page: number) => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/${FilePathService.encode(
        qualification
      )}/${FilePathService.encode(specification)}/${FilePathService.encode(
        unitCode
      )}/search`,
      params:
        search.trim().length === 0
          ? {
              limit: 10000,
              offset: 0,
            }
          : {
              limit: 10000,
              offset: 0,
              text: search.trim(),
            },
    }
  }
  const [fileUrl, setFileUrl] = useState(
    getFileToSpecificationsUrl(searchInput, 1)
  )

  const backButtonUpdate = useBackButtonUpdate()
  useEffect(() => {
    backButtonUpdate({
      returnUrl: `/aam/${qualification}/${escapeSlashes(specification)}`,
      text: `Unit/Component`,
    })
  }, [history.location])

  const getFilesToSpecification = useAsyncTaskAxios<
    AxiosResponse<{ items: SpecificationFile[]; size: number }>
  >(axios, fileUrl)

  useEffect(() => {
    getFilesToSpecification.start()
  }, [fileUrl])

  const paginatedFiles = useMemo(() => {
    const offset = calculateOffset(currentPage)
    return getFilesToSpecification.result?.data.items.slice(
      offset,
      offset + limit
    )
  }, [currentPage, limit, getFilesToSpecification.result])

  const handleSearch = () => {
    setFileUrl(getFileToSpecificationsUrl(searchInput, 1))
    setSearchTerm(searchInput)
    setCurrentPage(1)
  }

  const handleFilterClear = () => {
    setFileUrl(getFileToSpecificationsUrl('', currentPage))
    setSearchInput('')
  }

  const pageAmount = useMemo(
    () => Math.ceil((getFilesToSpecification.result?.data.size || 0) / limit),
    [limit, getFilesToSpecification.result]
  )

  const isShowPerPageContainer = useMemo(
    () => pageAmount !== 1 || limit !== 20,
    [pageAmount, limit]
  )

  if (getFilesToSpecification.started && getFilesToSpecification.pending) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }

  const onSetLimit = (pageLimit: number) => {
    setCurrentPage(1)
    setLimit(pageLimit)
  }

  return (
    <div>
      <div className="specification-files container">
        <strong className="header">{unitCode}</strong>
        <div className="search-files">
          <SearchBar
            val={searchInput}
            placeholder="Search for a topic, term or reference"
            filterChanged={setSearchInput}
            onFilterClear={handleFilterClear}
            onClick={handleSearch}
            disableSearch={false}
            dataTestid="specifications-search-bar"
          />
        </div>

        {getFilesToSpecification.result &&
        getFilesToSpecification.result.data.items.length === 0 ? (
          <div className="files-list-container">
            <p data-testid="no-search-results">
              0 results found
              {!!searchTerm && (
                <span>
                  {' '}
                  for
                  <strong>{` "${searchTerm}"`}</strong>
                </span>
              )}
              .
            </p>
            <p>
              Please refer to the mapping grid or try searching for something
              else.
            </p>
          </div>
        ) : (
          <div>
            <div className="files-list-container">
              <div className="search-info">
                <div>
                  For each unit/component there will a mapping grid which
                  contains important information.
                </div>
                <div>
                  For each question set (QS) there will be a number of files.
                  These may include the questions and mark scheme (QMS), maps
                  (MAP), and audio tracks (Track).
                </div>
                <div className="amount-files">
                  Number of files available for download:{' '}
                  {getFilesToSpecification.result &&
                    getFilesToSpecification.result.data.size}
                </div>
              </div>

              {paginatedFiles?.map((file, index) => (
                <FileRow file={file} key={file.fileName} index={index} />
              ))}
            </div>
            <div className="pagination-container">
              <PagePagination
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
                pagesAmount={pageAmount}
              />
              {isShowPerPageContainer && (
                <Dropdown
                  group
                  isOpen={isPageLimitDropdownOpen}
                  toggle={toggleDropDown}
                >
                  <DropdownToggle className="page-limit-dropdown">
                    Show per page:
                    <div className="selected-size">
                      {limit}
                      <FontAwesomeIcon
                        className="d-inline-block align-middle dropdown-icon"
                        icon={faCaretDown}
                      />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu right className="mt-3">
                    {availablePageLimit.map((pageLimit) => (
                      <DropdownItem
                        className={
                          pageLimit === limit ? 'selected-page-size' : ''
                        }
                        onClick={() => onSetLimit(pageLimit)}
                      >
                        {pageLimit === limit && (
                          <FontAwesomeIcon
                            className="selected-page-size-icon"
                            icon={faCheck}
                          />
                        )}
                        <div className="page-limit">{pageLimit}</div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
