import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Label, Row, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faPaperPlane,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import ReactGA from 'react-ga'
import { useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'

import { RemoveUserModal } from './remove-user-modal'

interface IUser {
  name: string
  surname: string
  email: string
  id?: string
  role?: string
  centreId: string
  confirmed: boolean
  allowDelete: boolean
  onDelete: (email: string) => void
  className?: string
}

export const User: React.FC<IUser> = ({
  name,
  surname,
  email,
  confirmed,
  allowDelete,
  centreId,
  role,
  onDelete,
  id,
  className = '',
}): JSX.Element => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const resendInviteMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/sendWelcomeMail`,
      method: 'POST',
      data: {
        email: email,
      },
    }
  }, [email, centreId])

  const resendInviteTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    resendInviteMemo
  )

  const deleteUserMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/users/${id}`,
      method: 'DELETE',
    }
  }, [centreId, id])

  const deleteUserTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    deleteUserMemo
  )

  const deleteInProgress = useMemo(
    () => deleteUserTask.started && deleteUserTask.pending,
    [deleteUserTask.started, deleteUserTask.pending]
  )

  useEffect(() => {
    if (deleteUserTask.result) {
      const t = setTimeout(() => {
        setShowDeleteModal(false)
        onDelete(email)
      }, 500)
      return () => clearTimeout(t)
    }
  }, [deleteUserTask.result, email, onDelete])

  useEffect(() => {
    if (deleteUserTask.error) {
      ReactGA.exception({
        description: `Failed to delete user - ${id}`,
        fatal: false,
      })
    }
  }, [deleteUserTask.error, id])

  useEffect(() => {
    if (resendInviteTask.error) {
      ReactGA.exception({
        description: `Failed to resend invite - ${id}`,
        fatal: false,
      })
    }
  }, [resendInviteTask.error, id])
  return (
    <Row
      className={`${className} management-user align-items-center py-3 px-4`}
    >
      <Col>
        <Label className="d-block m-0 font-larger">
          {name} {surname}
        </Label>
        <Label className="d-block m-0 user-email">{email}</Label>
      </Col>
      {!confirmed && (
        <Col
          xs="auto"
          className="justify-content-center align-items-center d-flex"
        >
          <Button
            size="sm"
            color="link"
            disabled={
              (resendInviteTask.pending && resendInviteTask.started) ||
              resendInviteTask.result !== null
            }
            className=" font-smaller px-3 py-2"
            onClick={() => {
              ReactGA.event({
                category: 'User',
                action: 'Resend Invite',
                label: id,
              })
              resendInviteTask.start()
            }}
          >
            {resendInviteTask.pending && resendInviteTask.started && (
              <Spinner
                data-testid="resend-spinner"
                className="mr-md-2"
                size="sm"
              />
            )}
            {!(resendInviteTask.pending && resendInviteTask.started) && (
              <FontAwesomeIcon
                title={
                  resendInviteTask.result
                    ? 'Invite has been sent'
                    : resendInviteTask.error
                    ? 'Resend failed, clic to try again'
                    : 'Click to resend invite'
                }
                icon={resendInviteTask.result ? faCheck : faPaperPlane}
                className={`mr-md-2 ${
                  resendInviteTask.error ? 'text-danger' : ''
                }`}
              />
            )}

            {resendInviteTask.result === null && (
              <span
                className={`d-none d-md-inline-block  ${
                  resendInviteTask.error ? 'text-danger' : ''
                }`}
              >
                Resend invite
              </span>
            )}
            {resendInviteTask.result && (
              <span className="d-none d-md-inline-block">Invite sent</span>
            )}
          </Button>
        </Col>
      )}
      <Col xs="auto" className="text-right noselect">
        <Label
          className={`status-label m-0 py-2 px-4 ${
            confirmed ? 'confirmed' : 'pending'
          }`}
        >
          {confirmed ? <FontAwesomeIcon className="mr-2" icon={faCheck} /> : ''}
          {confirmed ? 'Confirmed' : 'Pending'}
        </Label>
      </Col>{' '}
      {allowDelete && (
        <Col xs="auto" className="text-right noselect">
          {deleteUserTask.started && deleteUserTask.pending ? (
            <Spinner size="sm" color="primary" />
          ) : (
            <Button
              color="link"
              className="px-0"
              onClick={() => setShowDeleteModal(true)}
            >
              <FontAwesomeIcon className="pointer mr-2" icon={faTrash} />
              Delete
            </Button>
          )}
        </Col>
      )}
      <RemoveUserModal
        name={name}
        surname={surname}
        email={email}
        role={role}
        centreId={centreId}
        isOpen={showDeleteModal}
        setOpen={setShowDeleteModal}
        deleteError={deleteUserTask.error}
        deleteInProgress={deleteInProgress || !!deleteUserTask.result}
        onSubmit={() => {
          ReactGA.event({
            category: 'User',
            action: `Delete user - ${role}`,
            label: id,
          })
          deleteUserTask.start()
        }}
      />
    </Row>
  )
}
