import React, { useMemo } from 'react'
import {
  Container,
  Navbar,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
} from 'reactstrap'
import { Link, NavLink, useHistory } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { useAuth0 } from '../auth'
import { isAdmin, isCambridgeInternational, isOCR } from '../util'
import { PathParam } from '../types'
import { BackLink } from './back-link/back-link'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPowerOff,
  faUsers,
  faCaretDown,
  faBookReader,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'
import { useAvailability } from '../common/hooks/use-availability'

export const NavBar = (): JSX.Element => {
  const { logout, isAuthenticated, user, currentCentre } = useAuth0()
  const { location } = useReactRouter<PathParam>()
  const history = useHistory()

  const { aamManagementAvailable, userManagementAvailable } = useAvailability()

  const separatorVisibleMemo = useMemo(
    () =>
      (isCambridgeInternational() && userManagementAvailable) ||
      (isOCR() && (aamManagementAvailable || userManagementAvailable)),
    [userManagementAvailable, aamManagementAvailable]
  )

  const isLandingPage = location.pathname === '/'

  return (
    <div className="nav-container">
      <Navbar color="faded" className="py-3" light expand="md">
        <Container>
          <Row className="w-100 align-items-center justify-content-end no-gutters">
            {!isLandingPage && (
              <Col>
                <BackLink centreId={currentCentre} />
              </Col>
            )}
            {isCambridgeInternational() && (
              <Col
                sm={isAuthenticated ? '2' : '10'}
                className={`${
                  !isLandingPage ? 'navbar-logo-container--sub-page' : ''
                } navbar-logo-container font-weight-bold text-black text-left`}
              >
                <div
                  className="pointer"
                  onClick={() => history.push('/')}
                  data-testid="navbar-tenant-logo-homepage-pointer"
                >
                  <img
                    src="/ci-logo.svg"
                    height="36"
                    alt="Cambridge Assessment International Education"
                  />
                </div>
                {!isAuthenticated && (
                  <div className="d-none d-lg-block site-heading">
                    Grade Submission System
                  </div>
                )}
              </Col>
            )}
            {isOCR() && (
              <Col
                sm={isAuthenticated ? '2' : '8'}
                className={`${
                  !isLandingPage ? 'navbar-logo-container--sub-page' : ''
                } navbar-logo-container font-weight-bold text-black text-left`}
              >
                <div
                  className="pointer"
                  onClick={() => history.push('/')}
                  data-testid="navbar-tenant-logo-homepage-pointer"
                >
                  <img src="/ocr-logo.svg" height="60" alt="OCR" />
                </div>
                {!isAuthenticated && (
                  <div className="d-none d-lg-block site-heading">
                    Grade Submission System
                  </div>
                )}
              </Col>
            )}

            <Col sm={isAuthenticated ? '10' : '6'} className="text-right">
              <Nav
                className="ml-auto align-items-end align-items-md-center  justify-content-end"
                navbar
              >
                {isAuthenticated && user && (
                  <>
                    <NavItem className="d-none d-md-block mr-lg-3">
                      <NavLink
                        className="font-weight-bold text-secondary mr-5"
                        to="/help-and-contact"
                        data-testid="navbar-help-and-contact-link"
                      >
                        Help &amp; Contact
                      </NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle
                        nav
                        className="ml-3 p-0 nav-toggle"
                        data-testid="header-toggle"
                      >
                        <div className="d-inline-block align-middle">
                          <div className="font-weight-bold  text-color333">
                            {user.roles.join(' | ')}
                          </div>
                          {currentCentre && (
                            <div className="text-secondary ">
                              {`Centre: ${currentCentre}`}
                            </div>
                          )}
                        </div>
                        <FontAwesomeIcon
                          className="d-inline-block ml-3 align-middle text-color333"
                          icon={faCaretDown}
                        />
                      </DropdownToggle>

                      <DropdownMenu right className="mt-3">
                        {isAdmin(user) && (
                          <>
                            {isOCR() && aamManagementAvailable && (
                              <Link className="no-text-hover" to="/manage/aam">
                                <DropdownItem
                                  id="qaManageBtn"
                                  className="text-left py-3 font-weight-bold mr-4 "
                                >
                                  <FontAwesomeIcon
                                    icon={faBookReader}
                                    className="mr-3"
                                  />
                                  Manage Additional Assessment Material
                                </DropdownItem>
                              </Link>
                            )}

                            {userManagementAvailable && (
                              <>
                                <Link
                                  className="no-text-hover"
                                  to="/manage/teachers"
                                >
                                  <DropdownItem
                                    id="qaManageBtn"
                                    className="text-left py-3 font-weight-bold mr-4 "
                                  >
                                    <FontAwesomeIcon
                                      icon={faUsers}
                                      className="mr-3"
                                    />
                                    Manage Teachers
                                  </DropdownItem>
                                </Link>
                                <Link
                                  className="no-text-hover"
                                  to="/manage/signoff"
                                >
                                  <DropdownItem
                                    id="qaManageBtn"
                                    className="text-left py-3 font-weight-bold mr-4 "
                                  >
                                    <FontAwesomeIcon
                                      icon={faUserShield}
                                      className="mr-3"
                                    />
                                    Manage Head of Centre
                                  </DropdownItem>
                                </Link>
                              </>
                            )}

                            {separatorVisibleMemo && (
                              <div className="separator" />
                            )}
                          </>
                        )}
                        <DropdownItem
                          id="qsLogoutBtn"
                          className="text-left text-redonblack py-3 font-weight-bold mr-4 "
                          onClick={() =>
                            logout({ returnTo: window.location.origin })
                          }
                          data-testid="logout-btn"
                        >
                          <FontAwesomeIcon icon={faPowerOff} className="mr-3" />
                          Log out
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                )}
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  )
}
