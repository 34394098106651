import React, { useState, useMemo, useEffect } from 'react'
import {
  Button,
  Col,
  Label,
  Row,
  Spinner,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useAsyncTaskAxios } from 'react-hooks-async'

import { ProjectModal } from '../modal/modal'
import { RsModalHeader } from '../modal/subcomponents/modal-header'
import { RsModalBody } from '../modal/subcomponents/modal-body'
import { Title } from '../title/title'
import { PreSignedUploadUrl } from '../../types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-solid-svg-icons'

enum UnitStatusChangeReason {
  Exception = 'Exception',
  QAProcess = 'QA Process',
}

interface IUnitReopenButton {
  btnText: string
  header: string
  subheader?: string
  ancillery?: string
  centreId: string
  unitId: string
  gradedCandidates: number
  totalCandidates: number
  unitReopened?: (reopened: boolean) => void
}

export const UnitReopenButton: React.FC<IUnitReopenButton> = ({
  btnText,
  header,
  subheader = '',
  ancillery,
  centreId,
  unitId,
  unitReopened,
}) => {
  const [statusChangeModalOpen, setStatusChangeModalOpen] = useState<boolean>(
    false
  )
  const [unitStatusChangeReason, setUnitStatusChangeReason] = useState<
    UnitStatusChangeReason
  >()

  const getUnitStatusChangeConfig = useMemo<AxiosRequestConfig>(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/units/${unitId}/reopen`,
      params: {
        changeReason: unitStatusChangeReason,
      },
      method: 'PATCH',
    }),
    [centreId, unitId, unitStatusChangeReason]
  )

  const changeUnitStatus = useAsyncTaskAxios<AxiosResponse<PreSignedUploadUrl>>(
    axios,
    getUnitStatusChangeConfig
  )

  const {
    start: startUnitStatusChange,
    started: unitStatusChangeStarted,
    pending: unitStatusChangePending,
    result: unitStatusChangeResult,
    error: unitStatusChangeError,
  } = changeUnitStatus

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    startUnitStatusChange()
  }

  useEffect(() => {
    if (
      !!unitReopened &&
      unitStatusChangeResult?.status === 200 &&
      statusChangeModalOpen === false
    ) {
      unitReopened(!!unitStatusChangeReason)
    }
  }, [
    unitReopened,
    unitStatusChangeResult,
    unitStatusChangeReason,
    statusChangeModalOpen,
  ])

  return (
    <>
      <Button
        color="success"
        className="text-uppercase"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setStatusChangeModalOpen(true)
        }}
        data-testid={`syllabus-progress-reopen-button-${unitId}`}
      >
        <FontAwesomeIcon icon={faPen} className="mr-2 font-normal" />
        <span className="font-normal">{btnText}</span>
      </Button>

      <ProjectModal
        isOpen={statusChangeModalOpen}
        toggle={() => setStatusChangeModalOpen(false)}
        centered
      >
        <RsModalHeader
          toggle={() => setStatusChangeModalOpen(false)}
          className="bg-white px-5 pt-5 pb-25"
        >
          <Row className="align-items-center justify-content-between mb-2">
            <Title title={header} subTitle={subheader} ancillery={ancillery} />
          </Row>
        </RsModalHeader>
        <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
          {!unitStatusChangeStarted && !unitStatusChangeResult && (
            <>
              <Row className="align-items-center justify-content-between mb-0">
                <h3 className="font-weight-bold h5 mb-1">
                  Change status from Submitted to Complete
                </h3>
              </Row>
              <Row className="align-items-center justify-content-between mb-2">
                <p className="font-larger">
                  Please choose the reason for changing the status:
                </p>
              </Row>
              <Form onSubmit={handleSubmit}>
                <Row className="align-items-center justify-content-between mb-4">
                  <Col className="m-0 mb-2 p-0">
                    {Object.values(UnitStatusChangeReason).map((reason) => (
                      <FormGroup check key={reason}>
                        <Label check>
                          <Input
                            className="syllabus-form-status-change-input"
                            checked={unitStatusChangeReason === reason}
                            name={reason}
                            type="radio"
                            value={reason}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => {
                              setUnitStatusChangeReason(reason)
                            }}
                            data-testid={`syllabus-form-status-change-input-${reason
                              .toLowerCase()
                              .replace(/\s/, '-')}`}
                          />
                          <p className="font-larger mb-0">{reason}</p>
                        </Label>
                      </FormGroup>
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Button
                    color="primary"
                    disabled={!unitStatusChangeReason}
                    type="submit"
                  >
                    Re-open form
                  </Button>
                </Row>
              </Form>
            </>
          )}
          {unitStatusChangeStarted &&
            unitStatusChangePending &&
            !unitStatusChangeResult && (
              <Row className="align-items-center justify-content-center">
                <Spinner size="md" className="m-auto p-auto" />
              </Row>
            )}
          {!unitStatusChangeStarted &&
            !unitStatusChangePending &&
            unitStatusChangeResult && (
              <Row className="align-items-center justify-content-start">
                <Col className="pl-0 text-left">
                  <p className="font-larger text-success">
                    Syllabus form status successfully changed to{' '}
                    <strong>Complete</strong>!
                  </p>
                </Col>
              </Row>
            )}
          {unitStatusChangeError && (
            <Row className="align-items-center justify-content-start mt-4">
              <Col className="pl-0 mt-1 text-left">
                <p className="font-larger text-danger">
                  Failed to update syllabus status, please try again and if the
                  problem persists please contact us.
                </p>
              </Col>
            </Row>
          )}
        </RsModalBody>
      </ProjectModal>
    </>
  )
}
