import React, { useEffect, useMemo, useState } from 'react'
import { Container, Row, Col, Label } from 'reactstrap'
import { isOCR } from '../../util'
import { UnitCandidate, MissingData, CentreUnit } from '../../types'
import {
  CandidatesSorter,
  candidatesSorting,
  toggleSorting,
} from './utilities/candidates-sorter/candidates-sorter'
import {
  buildGradingMap,
  candidatesFiltering,
} from './utilities/candidates-filter'
import { Candidate } from './candidate/candidate'
import { CandidatesSearchLine } from './utilities/candidates-search-line'
import { CandidateErrorInfo } from './candidate/candidate-error-info'
import Switch from 'react-switch'
import { isEqual } from 'lodash'
import { TabList } from '../tabs/tab-list'
import { Tab } from '../tabs/subcomponents/tab'
import { TabContentLabelWithCount } from '../tab-content-label-with-count'
import getTextFromToken from '../../tokenised-text'
import { ICandidateUpdateExtended, UNRANKED_GRADE } from './constants'
import { faLevelUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { compareTwoLowerCaseStrings } from '../../util'

const applySearchFilter = (
  candidates: UnitCandidate[],
  filter: string
): UnitCandidate[] => {
  const filt = filter.toLowerCase()
  return candidates.filter(
    (c) =>
      c.name.toLowerCase().includes(filt) ||
      c.candidateNumber.replace(/^0+/, '').toLowerCase().startsWith(filt)
  )
}

const hasErrors = (missingData: MissingData): boolean => {
  // ** feature/ISPR-1517 hide ties and ranks **
  // return (
  //   missingData.missingPredicted +
  //     missingData.missingRank +
  //     missingData.tiedRank >
  //   0
  // )

  return missingData.missingPredicted > 0
}

// ** feature/ISPR-1517 hide ties and ranks **
// const getErrorCounts = (candidates: SyllabusCandidate[], allowTies?:boolean): MissingData => {

const getErrorCounts = (
  candidates: UnitCandidate[],
  unit: CentreUnit,
  isOcr: boolean
): MissingData => {
  // ** feature/ISPR-1517 hide ties and ranks **
  // const valids = candidates.filter(
  //   (x) => x.grade && (x.rank || UNRANKED_GRADES.includes(x.grade))
  // )

  // const valids = candidates.filter(
  //   (x) => x.grade && UNRANKED_GRADES.includes(x.grade)
  // )
  // const counts = countBy(
  //   valids
  //     .filter((it) => !UNRANKED_GRADES.includes(it.grade || ''))
  //     .map((x) => {
  //       // ** feature/ISPR-1517 hide ties and ranks **
  //       // return { key: `${x.grade}_${x.rank}` }

  //       return { key: `${x.grade}` }
  //     }),
  //   (x) => x.key
  // )

  // const tiedRankCount = values(counts)
  // .filter((x) => x > 1)
  // .reduce((a, c) => a + c, 0)

  // const errors = {
  //   missingPredicted: candidates.filter((x) => !x.grade).length,
  //   missingRank: candidates.filter(
  //     (x) => x.grade && !x.rank && !UNRANKED_GRADES.includes(x.grade || '')
  //   ).length,
  //   tiedRank: allowTies ? 0 : tiedRankCount,
  // }

  const errors: MissingData = {
    missingPredicted: candidates.filter((candidate) => !candidate.grade).length,
  }

  return errors
}

interface ICandidatesList {
  candidates: UnitCandidate[]
  patchCandidate: (
    // change: { id: string; grade: string; rank?: number },
    change: ICandidateUpdateExtended,
    cb: (good: boolean) => void
  ) => void
  lastUpdated: number
  upToDate: boolean
  gradeFilter: string
  // allowTies?: boolean
  showErrors: boolean
  viewOnly: boolean
  candidatesPatchingProgress: { [key: string]: string | undefined }
  clearPatchingErrors: (ids?: string[]) => void
  toggleShowErrors: (newShow: boolean) => void
  approvalDisabled: (state: boolean) => void
  downloadCallback: () => void
  unit: CentreUnit
  openSetXGradeModal: () => void
  isShowSetXGradeButton: boolean
}

export const CandidatesList: React.FC<ICandidatesList> = ({
  approvalDisabled,
  showErrors,
  toggleShowErrors,
  candidates,
  patchCandidate,
  // ** feature/ISPR-1517 hide ties and ranks **
  // allowTies,
  lastUpdated,
  gradeFilter,
  candidatesPatchingProgress,
  clearPatchingErrors,
  upToDate,
  viewOnly,
  downloadCallback,
  unit,
  openSetXGradeModal,
  isShowSetXGradeButton,
}): JSX.Element => {
  const [searchFilter, setSearchFilter] = useState('')
  const [currentSorting, setCurrentSorting] = useState('NAME-down')
  const [showOnlyErrors, setShowOnlyErrors] = useState(false)

  const toggleSort = useMemo(
    () => (newSorting: string) => {
      setCurrentSorting(toggleSorting(newSorting, currentSorting))
    },
    [currentSorting, setCurrentSorting]
  )

  const gradingMap = useMemo(() => buildGradingMap(candidates), [candidates])
  // ** feature/ISPR-1517 hide ties and ranks **
  // const errors = getErrorCounts(candidates,allowTies)
  const errors = useMemo(() => getErrorCounts(candidates, unit, isOCR()), [
    candidates,
    unit,
  ])

  const [grading, setGrading] = useState<string[]>([])

  useEffect(() => {
    const grades = candidates.reduce(
      (acc: string[], curr) => [...acc, ...curr.allowedGrades],
      []
    )
    const newGrading = Array.from(new Set(grades).values())

    if (!isEqual(grading, newGrading)) {
      setGrading(newGrading)
    }
  }, [candidates, grading])

  const candidatesFilteredIds = useMemo(
    () =>
      candidatesFiltering(
        applySearchFilter(candidates, searchFilter),
        gradeFilter
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter, gradeFilter]
  )

  const isGraded = (candidate: UnitCandidate) => !candidate.grade

  const filteredCandidates = useMemo(() => {
    let searchFiltered = candidates.filter((it) =>
      candidatesFilteredIds.includes(it.id)
    )
    // ** feature/ISPR-1517 hide ties and ranks **
    // if (showOnlyErrors) {
    //   let ranks = countBy(Array.from(searchFiltered, (x) => x.rank))
    //   searchFiltered = searchFiltered.filter(
    //     (x) =>
    //       (gradeFilter === 'ALL' && !x.grade) ||
    //       (gradeFilter !== 'ALL' && !x.rank) ||
    //       (gradeFilter !== 'ALL' && ranks[`${x.rank}`] > 1)
    //   )
    // }
    if (showOnlyErrors) {
      searchFiltered = searchFiltered.filter(isGraded)
    }
    return searchFiltered
  }, [candidates, candidatesFilteredIds, showOnlyErrors, gradeFilter])

  const candidatesSortingOrder = useMemo(
    () => candidatesSorting(filteredCandidates, currentSorting, { grading }),
    [currentSorting, grading, filteredCandidates]
  )

  const sortedCandidates = useMemo(
    () =>
      [...filteredCandidates].sort(
        (a, b) =>
          candidatesSortingOrder.indexOf(a.id) -
          candidatesSortingOrder.indexOf(b.id)
      ),
    [filteredCandidates, candidatesSortingOrder]
  )

  useEffect(() => {
    if (!hasErrors(errors)) {
      toggleShowErrors(false)
    }
  }, [errors, toggleShowErrors])

  const gradedCandidatesCount = useMemo(
    () => candidates.filter((x) => x.grade).length,
    [candidates]
  )

  // ** feature/ISPR-1517 hide ties and ranks **
  const filteredErrors = useMemo(() => {
    // const errors = getErrorCounts(filteredCandidates, allowTies)
    return getErrorCounts(filteredCandidates, unit, isOCR())
    // }, [filteredCandidates, allowTies])
  }, [filteredCandidates, unit])

  // ** feature/ISPR-1517 hide ties and ranks **
  // const errorGradesList = useMemo(() => {
  //   let errGrades: any = []
  //   if (showErrors) {
  //     const grouped = groupBy(
  //       candidates
  //         .filter((x) => x.grade && x.rank)
  //         .map((x) => {
  //           return { grade: x.grade, key: `${x.grade}_${x.rank}` }
  //         }),
  //       (x) => x.grade
  //     )
  //     const mapped: [string, number][] = map(grouped, (val, key) => {
  //       const counts = countBy(val, (x) => x.key)
  //       const pairedRanks = values(counts)
  //         .filter((x) => x > 1)
  //         .reduce((a, c) => a + c, 0)
  //       return [key, pairedRanks]
  //     })
  //     const objectifie = mapped.reduce((o, [key, value]) => {
  //       if (value > 0) {
  //         o[key] = value
  //       }
  //       return o
  //     }, {} as Record<string, number>)
  //     const pairedKeys = keys(objectifie)

  //     const grouped = groupBy(
  //       candidates
  //         .filter((x) => x.grade)
  //         .map((x) => {
  //           return { grade: x.grade, key: `${x.grade}` }
  //         }),
  //       (x) => x.grade
  //     )

  //     const noGrades = keys(
  //       countBy(
  //         candidates.filter(
  //           (x) => x.grade && !UNRANKED_GRADES.includes(x.grade) && !x.rank
  //           (x) => x.grade && !UNRANKED_GRADES.includes(x.grade)
  //         ),
  //         'grade'
  //       )
  //     )
  //     const pairedErrors = allowTies ? [] : pairedKeys
  //     errGrades = uniq([...pairedErrors, ...noGrades])
  //     errGrades = uniq([...noGrades])
  //   }
  //   return errGrades
  //   }, [showErrors, candidates, allowTies])
  // }, [showErrors, candidates])

  useEffect(() => {
    approvalDisabled(hasErrors(errors))
    if (!hasErrors(errors)) setShowOnlyErrors(false)
  }, [candidates, approvalDisabled, toggleShowErrors, errors])

  const numberOfGradeFilterInstances = (key: string): number => {
    let instances = 0

    for (const gradeKey of Object.keys(gradingMap)) {
      if (compareTwoLowerCaseStrings(gradeKey, key)) {
        instances += gradingMap[gradeKey]
      }
    }

    return instances
  }

  return (
    <Container>
      <CandidatesSearchLine
        approvalDisabled={hasErrors(errors)}
        lastUpdated={lastUpdated}
        upToDate={upToDate}
        checkForErrorsClick={() => {
          if (hasErrors(errors)) {
            toggleShowErrors(true)
          }
        }}
        filterChanged={setSearchFilter}
        downloadCallback={downloadCallback}
        viewOnly={viewOnly}
        openSetXGradeModal={openSetXGradeModal}
        isShowSetXGradeButton={isShowSetXGradeButton}
      />

      <Row>
        <Col xs="auto" className="font-weight-bold text-secondary">
          <Label className="m-0">View All</Label>
          <Row className="no-gutters">
            <Tab
              className="mt-3"
              autoSize
              to={`?grade=${encodeURIComponent('ALL')}`}
            >
              <TabContentLabelWithCount
                label={getTextFromToken('assessedGrades')}
                isActive={gradeFilter === 'ALL'}
                outOfCount={candidates.length}
                count={gradedCandidatesCount}
                countPending={false}
                className="candidates-filter-main-tab"
                errorMessage={
                  showErrors && candidates.filter((x) => !x.grade).length > 0
                    ? 'Not all candidates graded'
                    : undefined
                }
              />
            </Tab>
          </Row>
        </Col>
        <Col className="font-weight-bold text-secondary">
          <Label className="m-0">{getTextFromToken('filterText')}</Label>
          <TabList className="mb-45">
            {grading.map((key) => (
              <Tab
                className="mt-3"
                autoSize
                key={`grade-${key}`}
                to={`?grade=${encodeURIComponent(key)}`}
                disabled={(numberOfGradeFilterInstances(key) || 0) === 0}
              >
                <TabContentLabelWithCount
                  label={key}
                  isActive={gradeFilter === key}
                  count={numberOfGradeFilterInstances(key) || 0}
                  countPending={false}
                  className="candidates-filter-tab-content"
                  errorMessage={undefined}
                  // ** feature/ISPR-1517 hide ties and ranks **
                  // errorMessage={
                  //   errorGradesList.includes(key)
                  //     ? 'Ranks in this grade need fixing'
                  //     : undefined
                  // }
                />
              </Tab>
            ))}
          </TabList>
        </Col>
      </Row>
      {showErrors && (
        <Row className="align-items-end mb-45">
          {/* feature/ISPR-1517 hide ties and ranks */}
          {/* {showErrors &&
            filteredErrors.missingPredicted +
              filteredErrors.missingRank +
              filteredErrors.tiedRank >
              0 && (
              <Col lg={12}>
                <CandidateErrorInfo
                  missingPredicted={filteredErrors.missingPredicted}
                  missingRank={filteredErrors.missingRank}
                  tiedRank={filteredErrors.tiedRank}
                />
              </Col>
            )} */}
          {showErrors && (
            <CandidateErrorInfo
              missingPredicted={filteredErrors.missingPredicted}
            />
          )}
          <Col className="text-right">
            <label>
              <span className="mr-2 font-weight-bold align-middle">
                Show only errors
              </span>
              <Switch
                className="error-switch align-middle"
                height={20}
                width={32}
                handleDiameter={16}
                checked={showOnlyErrors}
                onChange={() => setShowOnlyErrors(!showOnlyErrors)}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#c31918"
              />
            </label>
          </Col>
        </Row>
      )}

      {/** feature/ISPR-1517 hide ties and ranks **/}
      {/* {UNRANKED_GRADES.includes(gradeFilter) && (
        <Row className="mt-3 mb-4">
          <Col>
            <div className="border rounded border-secondary px-35 py-25 font-weight-bold">
              <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
              There is no need to rank these candidates
            </div>
          </Col>
        </Row>
      )} */}

      <CandidatesSorter
        currentSorting={currentSorting}
        filter={gradeFilter}
        showBottomBorder={sortedCandidates.length === 0}
        toggleSorting={toggleSort}
        unit={unit}
      />

      {sortedCandidates.map((c, idx) => (
        <>
          <Candidate
            // ** feature/ISPR-1517 hide ties and ranks **
            // isErroneous={
            //   (showErrors && gradeFilter === 'ALL' && !c.grade) ||
            //   (showErrors && gradeFilter !== 'ALL' && !c.rank) ||
            //   (showErrors &&
            //     gradeFilter !== 'ALL' &&
            //     !allowTies &&  countBy(Array.from(sortedCandidates, (x) => x.rank))[
            //       `${c.rank}`
            //     ] > 1)
            // }
            isErroneous={showErrors && isGraded(c)}
            key={`candidate-${c.id}`}
            idx={idx + 1}
            candidate={c}
            grading={grading}
            candidatesInGrade={gradingMap[c.grade || ''] || 0}
            patchingProgress={candidatesPatchingProgress[c.id]}
            filter={gradeFilter}
            viewOnly={viewOnly}
            unit={unit}
            onChange={(change: any, cb: any) =>
              patchCandidate(
                {
                  id: c.id,
                  ...change,
                },
                cb
              )
            }
          />
          {(candidatesPatchingProgress[c.id] || '').startsWith('error') && (
            <Row
              className="m-0 px-25 py-1 bg-danger text-white font-weight-bold pointer"
              onClick={() => clearPatchingErrors([c.id])}
            >
              <Col className="m-0">
                <FontAwesomeIcon
                  className="ml-2 mr-3 d-inline-flex"
                  rotation={90}
                  icon={faLevelUp}
                />
                {/* feature/ISPR-1517 hide ties and ranks */}
                {/* <span>
                  {candidatesPatchingProgress[c.id] === 'error-g' ?
                    'Grade change can not be completed for some reason, please try again.' :
                    'Rank order can not be completed for some reason, please try again.'
                  }
                </span> */}
                <span>
                  {
                    'Grade change can not be completed for some reason, please try again.'
                  }
                </span>
              </Col>
            </Row>
          )}
        </>
      ))}
      {sortedCandidates.length === 0 && (
        <Row>
          <Col className="text-center">
            <Label
              className="font-weight-bold mt-3 text-secondary"
              style={{ fontSize: '24px' }}
            >
              No results
            </Label>
          </Col>
        </Row>
      )}
    </Container>
  )
}
