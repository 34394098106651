import React, { useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import { CentreUnit, UnitCandidate } from '../../../types'
import {
  candidateColumnSizes,
  ICandidateUpdate,
  UNRANKED_GRADE,
} from '../constants'

// ** feature/ISPR-1517 hide ties and ranks **
// import {
//   candidateColumnSizes,
//   ICandidateUpdate,
//   UNRANKED_GRADES,
// } from '../constants'
// import { RankSelector } from '../utilities/rank-selector'

interface ICandidateFull {
  idx: number
  candidate: UnitCandidate
  grading: string[]
  candidatesInGrade: number
  isErroneous: boolean
  viewOnly: boolean
  patchingProgress?: string
  onChange: (update: ICandidateUpdate, cb?: (good: boolean) => void) => void
  unit: CentreUnit
}

export const CandidateFull: React.FC<ICandidateFull> = ({
  idx,
  candidate: {
    id,
    centreUnitId,
    candidateNumber,
    name,
    dob,
    grade,
    allowedGrades,
    lastUpdated,

    // ** feature/ISPR-1517 hide ties and ranks **
    // rank
  },
  grading,
  isErroneous,
  viewOnly,
  candidatesInGrade,
  patchingProgress,
  onChange,
  unit,
}): JSX.Element => {
  const columnCount = 4
  const sizes = useMemo(() => candidateColumnSizes[columnCount], [columnCount])

  return (
    <Row
      className={
        isErroneous
          ? 'reddish-background candidate-row px-25 py-3'
          : 'candidate-row px-25 py-3'
      }
      data-testid="candidate-row"
    >
      <Col className="text-left name" md={sizes[0]}>
        {name}
      </Col>
      <Col className="text-center font-normal font-weight-bold" md={sizes[1]}>
        {dob}
      </Col>
      <Col className="text-center font-normal font-weight-bold" md={sizes[2]}>
        {candidateNumber}
      </Col>
      <Col className="text-center" md={sizes[3]}>
        <span className="candidate-grade px-3 py-25">{grade}</span>
      </Col>
      {/** feature/ISPR-1517 hide ties and ranks **/}
      {/* <Col className="text-center" md={sizes[4]}>
        {(!viewOnly && !(UNRANKED_GRADES.includes(grade || ''))) ? (
          <RankSelector
            tabIndex={idx}
            value={rank}
            max={candidatesInGrade}
            disabled={patchingProgress === 'pending'}
            onChange={(val: number, cb?: (good: boolean) => void) => {
              onChange({ grade: grade || '', rank: val }, cb)
            }}
          />
        ) : (
          <Label className="font-weight-bold">{rank || '–'}</Label>
        )}
        {patchingProgress === 'pending' && (
          <div style={{ position: 'absolute', right: '-8px', top: '5px' }}>
            <Spinner size="sm" color="secondary" />
          </div>
        )}
      </Col> */}
    </Row>
  )
}
