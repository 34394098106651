import axios, { AxiosResponse } from 'axios'
import React, { useEffect, useMemo } from 'react'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import './unit-code.scss'
import { useBackButtonUpdate } from '../../common/context/back-button.context'

import { ResultsCard } from '../results-card/results-card'
import { Loading } from '../loading'
import { FilePathService } from '../../common/services/file-path.service'
import { escapeSlashes } from '../../util';

interface SpecificationsDTO {
  items: string[]
}

interface Props {
  qualification: string
  specification: string
}

export const UnitCodes: React.FC<Props> = ({
  qualification,
  specification,
}) => {
  const history = useHistory()
  const backButtonUpdate = useBackButtonUpdate()

  useEffect(() => {
    backButtonUpdate({
      returnUrl: `/aam/${qualification}`,
      text: `${qualification} Specifications`,
    })
    // avoid eprpetual re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location])

  const navigate = (unitCode: string) =>
    history.push(
      `/aam/${qualification}/${escapeSlashes(specification)}/${escapeSlashes(unitCode)}`
    )

  const getUnitCodesUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/${FilePathService.encode(
        qualification
      )}/${FilePathService.encode(specification)}/unitCodes`,
    }
  }, [qualification, specification])

  const getUnitCodes = useAsyncTaskAxios<AxiosResponse<SpecificationsDTO>>(
    axios,
    getUnitCodesUrl
  )

  useAsyncRun(getUnitCodes)

  if (getUnitCodes.started && getUnitCodes.pending) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }

  return (
    <Container className="unit-code-container container">
      <Row className="justify-content-center mx-auto">
        <Col>
          <h5 className="title">{specification}</h5>
          <p className="unit-code-info">
            Click on a button to view the additional assessment material for
            that unit/component.
          </p>
        </Col>
      </Row>
      <div className="cards-container">
        {getUnitCodes.result?.data.items.map((unit) => (
          <div className="unit-card" key={unit}>
            <ResultsCard
              title={unit}
              buttonText="See files"
              onClick={() => navigate(unit)}
              dataTestIdSuffix="aam-1"
            />
          </div>
        ))}
      </div>
    </Container>
  )
}
