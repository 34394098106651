import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, FormFeedback, Label, Row, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactGA from 'react-ga'
import { faExclamationTriangle, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { ManagedUser } from '../../types'
import { AsyncTask } from 'react-hooks-async'
import { AxiosError, AxiosResponse } from 'axios'
import { calculateAddUserEvent, ROLES } from '../../util'
import { AddUserEvents, AddUserModal } from './add-user-modal'
import { snakeCase } from 'snake-case'

const validateEmail = (email: string) => {
  /* eslint-disable */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  /* eslint-enable */
  return re.test(email.toLowerCase())
}

interface IAddUserForm {
  centreId: string
  buttonText: string
  role: string
  usersInRole: number
  getUserByEmail: (email: string) => Promise<ManagedUser | null>
  createUserTask: AsyncTask<AxiosResponse<ManagedUser>>
}

export const AddUserForm: React.FC<IAddUserForm> = ({
  centreId,
  buttonText,
  getUserByEmail,
  createUserTask,
  usersInRole,
  role,
}): JSX.Element => {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [emailInvalid, setEmailInvalid] = useState(false)

  const [addUserEvent, setAddUserEvent] = useState<null | AddUserEvents>(null)
  const [getUserByEmailPending, setGetUserByEmailPending] = useState(false)
  const [addUserModalData, setAddUserModalData] = useState<any | null>(null)

  useEffect(() => {
    if (createUserTask.result) {
      setName('')
      setSurname('')
      setEmail('')
    }
  }, [createUserTask.result])

  const createErrorFriendly = useMemo(() => {
    if (createUserTask.error) {
      const errorWithFriendly = createUserTask.error as AxiosError<{
        message: string
      }>
      if (errorWithFriendly && errorWithFriendly.response) {
        return errorWithFriendly.response.data.message
      }
    }
    return undefined
  }, [createUserTask.error])

  const inputs = useMemo(
    () => [
      {
        placeholder: 'First name',
        value: name,
        set: setName,
      },
      {
        placeholder: 'Last name',
        value: surname,
        set: setSurname,
      },
      {
        placeholder: 'Email address',
        value: email,
        set: (val: string) => {
          setEmail(val)
          setEmailInvalid(val.length !== 0 && !validateEmail(val))
        },
        invalid: emailInvalid,
      },
    ],
    [name, surname, email, emailInvalid, setName, setSurname, setEmail]
  )

  const pending = useMemo(
    () =>
      (createUserTask.started && createUserTask.pending) ||
      getUserByEmailPending,
    [createUserTask.pending, createUserTask.started, getUserByEmailPending]
  )
  const enabled = useMemo(
    () => !pending && (role !== ROLES.HOC || usersInRole === 0),
    [pending, role, usersInRole]
  )

  const buttonEnabled = useMemo(
    () =>
      name.length > 0 &&
      surname.length > 0 &&
      email.length > 0 &&
      validateEmail(email) &&
      enabled,
    [name, surname, email, enabled]
  )

  const addUser = useCallback(() => {
    ReactGA.event({
      category: 'User',
      action: `Added a ${role}`,
      label: `Centre : ${centreId}`,
    })

    createUserTask.start({
      data: {
        name,
        role,
        surname,
        email,
      },
    })
  }, [createUserTask, centreId, name, role, surname, email])

  const onAddUserClicked = useCallback(async () => {
    if (buttonEnabled && !pending) {
      setGetUserByEmailPending(true)
      const user = await getUserByEmail(email)
      const event =
        user === null ? null : calculateAddUserEvent(user, role, centreId)
      if (event === null) {
        addUser()
      } else {
        setAddUserModalData({
          name,
          surname,
          email,
          role,
          centreId,
          userRole: user?.role,
          userCentres: user?.centres,
        })
        setAddUserEvent(event)
      }
      setGetUserByEmailPending(false)
    }
  }, [
    buttonEnabled,
    pending,
    getUserByEmail,
    email,
    role,
    centreId,
    addUser,
    name,
    surname,
  ])

  return (
    <>
      <Row className="align-items-center mt-4">
        {inputs.map(({ placeholder, value, set, invalid }, idx) => (
          <Col
            className="mb-2"
            md={idx !== 2 ? 5 : 8}
            key={`user-form-col-${idx}`}
          >
            <input
              className={`px-3 font-weight-bold form-control ${
                invalid ? 'is-invalid' : ''
              }`}
              onChange={(e) => set(e.target.value)}
              value={value}
              placeholder={placeholder}
              disabled={!enabled}
              type="text"
              data-testid={`add-user-form-${snakeCase(placeholder)}`}
            />
          </Col>
        ))}
        <Col className="mb-2" md={6}>
          <Label
            className={`add-user-button px-3 py-2 mb-0 ${
              enabled && buttonEnabled ? 'enabled' : ''
            }`}
            onClick={onAddUserClicked}
            data-testid="add-user-form-add_user_button"
          >
            {pending ? (
              <Spinner size="sm" />
            ) : (
              <>
                <FontAwesomeIcon className="mr-3" icon={faPlus} />
                {buttonText}
              </>
            )}
          </Label>
        </Col>
      </Row>
      {createUserTask.error && (
        <Row>
          <Col md={{ size: 14, offset: 10 }}>
            <FormFeedback
              className={`mt-2 px-3 text-left ${
                createUserTask.error ? 'd-block' : ''
              }`}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
              {createUserTask.error && createErrorFriendly
                ? createErrorFriendly
                : 'Failed to create user, please try again'}
            </FormFeedback>
          </Col>
        </Row>
      )}
      <AddUserModal
        show={addUserEvent !== null && addUserModalData !== null}
        close={() => {
          setAddUserEvent(null)
          setAddUserModalData(null)
        }}
        onSubmit={addUser}
        addUserInProgress={pending}
        addUserError={createUserTask.error}
        event={addUserEvent || 0}
        data={addUserModalData}
      />
    </>
  )
}
