import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios, { AxiosResponse } from 'axios'
import React, { useMemo } from 'react'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { Button, Spinner } from 'reactstrap'
import { PreSignedUploadUrl } from '../../../types'
import { useDownloadFile } from '../../../common/hooks/use-download-file'

interface Props {
  centreId: string
}

export const CentreGradeButton: React.FC<Props> = ({
  centreId,
}): JSX.Element => {
  const getPresignedUrl = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/csv`,
    }),
    [centreId]
  )

  const getPresignedUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getPresignedUrl)

  const download = useDownloadFile({
    fileUrl: `${getPresignedUrlTask.result?.data.url}`,
    fileName: 'centre-grade-report.csv',
  })

  useAsyncRun(getPresignedUrlTask.result && download)

  const downloadPending = useMemo(() => {
    return (
      (getPresignedUrlTask.pending && getPresignedUrlTask.started) ||
      (getPresignedUrlTask.result != null &&
        download.pending &&
        download.started)
    )
  }, [download, getPresignedUrlTask])

  return (
    <Button
      color="success"
      onClick={() => getPresignedUrlTask.start()}
      data-testid="centre-syllabus-grade-report-download-button"
    >
      {!downloadPending && (
        <FontAwesomeIcon icon={faArrowToBottom} fixedWidth />
      )}
      {downloadPending && <Spinner size="sm" className="mr-2" />}
      <span>Centre grade report</span>
    </Button>
  )
}
