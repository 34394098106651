import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

import { faHeartbeat } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useExpiryPage } from '../../common/hooks/use-expiry-page'
import ciCaptureAvailabilityExpiredContent from './ci-capture-available-to-expired-content.md'
import ocrAvailabilityExpiredContent from './ocr-availability-expired-content.md'
import { isCambridgeInternational, isOCR } from '../../util'

export const ExpiryHoldingPage: React.FC = (): JSX.Element => {
  const [aamAvailabilityExpired, captureAvailabilityExpired] = useExpiryPage()
  const [source, setSource] = useState<string>()

  useEffect(() => {
    if (
      isCambridgeInternational() &&
      (aamAvailabilityExpired || captureAvailabilityExpired)
    )
      setSource(ciCaptureAvailabilityExpiredContent)
    else if (isOCR() && (aamAvailabilityExpired || captureAvailabilityExpired))
      setSource(ocrAvailabilityExpiredContent)
  }, [aamAvailabilityExpired, captureAvailabilityExpired])

  return (
    <div className="container">
      <div className="holding-page w-100 align-items-center bg-light">
        <FontAwesomeIcon icon={faHeartbeat} size="2x" />
        <ReactMarkdown source={source} />
      </div>
    </div>
  )
}
