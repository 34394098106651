import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Container } from 'reactstrap'
import ocrClosedMarkdown from './ocr-closed.md'

const SystemClosedHoldingPage = () => (
  <Container className="bg-light p-5">
    <div className="bg-light rounded markdown-content">
      <ReactMarkdown source={ocrClosedMarkdown} />
    </div>
  </Container>
)

export default SystemClosedHoldingPage
