import React from 'react'
import { ManagementPage } from './management-page'
import { ROLES } from '../../util'
import { withCentreId, withExpiration } from '../../enrichers'

let INFO_DESCRIPTION = <></>

if (process.env.REACT_APP_BU === 'ci') {
  INFO_DESCRIPTION = (
    // ** feature/ISPR-1517 hide ties and ranks **
    // <>
    //   The Head of Centre is responsible for reviewing and approving predicted
    //   grades and rank orders before submitting them to Cambridge International.
    //   They will also need to complete a <b>Head of Centre declaration.</b>
    //   Once you have added your Head of Centre, they will receive a confirmation
    //   email with a verification link. They will need to click the link to verify
    //   their account before they can review and approve{' '}
    //   <b>predicted grades and rank orders.</b>
    // </>
    <>
      The Head of Centre is responsible for reviewing and approving
      school-assessed grades before submitting them to Cambridge International.
      They will also need to complete a <b>Head of Centre declaration.</b>
      <br />
      Once you have added your Head of Centre, they will receive a confirmation
      email with a verification link. They will need to click the link to verify
      their account before they can review and approve{' '}
      <b>school-assessed grades.</b>
    </>
  )
} else if (process.env.REACT_APP_BU === 'ocr') {
  INFO_DESCRIPTION = (
    // ** feature/ISPR-1517 hide ties and ranks **
    // <>
    //   The Head of Centre is responsible for reviewing and approving centre assessment grades and
    //   rank orders before submitting them to OCR. They will also need to complete a Head of centre
    //   declaration. If your current Head of Centre is not available or needs to delegate this role,
    //   remove your current Head of Centre below and then add the name and email address for their
    //   replacement. They will receive a confirmation email with a verification link. They will need
    //   to click the link to verify their account before they can review and approve data.
    // </>
    <>
      The Head of Centre is responsible for reviewing and approving teacher
      assessed grades before submitting them to OCR. They will also need to
      complete a Head of Centre declaration.
      <br />
      If your current Head of Centre is not correct, not available or needs to
      delegate this role, remove your current Head of Centre below and then add
      the name and email address for their replacement. They will receive a
      confirmation email with a verification link. They will need to click the
      link to verify their account before they can review and approve data.
    </>
  )
}

export const ManageSignOffPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => (
  <ManagementPage
    title="Set up Head of Centre"
    centreId={centreId}
    description={INFO_DESCRIPTION}
    buttonText="Add Head of Centre"
    emptyListMessage="No head of centre has been assigned"
    role={ROLES.HOC}
    currentUsersLabel="Current Head of Centre"
  />
)

export const ManageSignOffPageWithRoute = withCentreId(ManageSignOffPage)
export const ManageSignOffPageWithExpiration = withExpiration(ManageSignOffPage)
export const ManageSignOffPageWithRouteAndExpiration = withExpiration(
  ManageSignOffPageWithRoute
)
