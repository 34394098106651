import React, { useState, useMemo, useEffect } from 'react'
import ReactGA from 'react-ga'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { useBackButtonUpdate } from '../../common/context/back-button.context'
import useReactRouter from 'use-react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, Container, Spinner } from 'reactstrap'
import { SearchBar } from '../search-bar/search-bar'
import { useDownloadFile } from '../../common/hooks/use-download-file'
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { PreSignedUploadUrl } from '../../types'
import { isCambridgeInternational, isOCR } from '../../util'
import { DownloadCentersReportButton } from './components/download-centers-report-button'
import { useAvailability } from '../../common/hooks/use-availability'

export const CambridgeAdminControls: React.FC = (): JSX.Element => {
  const { history: routerHistory } = useReactRouter()
  const backButtonUpdate = useBackButtonUpdate()
  const history = useHistory()

  const { gradingAvailable } = useAvailability()

  const getPresignedUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/progressUrl`,
    }
  }, [])
  const getPresignedAAMDownloadLogUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/aam-download-log/url`,
    }
  }, [])
  const getAuditPresignedUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/auditlog/url`,
    }
  }, [])

  useEffect(() => {
    if (isCambridgeInternational()) {
      backButtonUpdate(null)
      return
    }
    backButtonUpdate({
      returnUrl: '/',
      text: 'Home',
    })
  }, [history.location])

  const getPresignedAAMDownloadLogUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getPresignedAAMDownloadLogUrl)

  const getPresignedAuditUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getAuditPresignedUrl)

  const getPresignedUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getPresignedUrl)

  const downloadCentreProgress = useDownloadFile({
    fileUrl: `${getPresignedUrlTask.result?.data.url}`,
    fileName: `centres_download.csv`,
  })
  const downloadAAMDownloadLogs = useDownloadFile({
    fileUrl: `${getPresignedAAMDownloadLogUrlTask.result?.data.url}`,
    fileName: `aam_download_log.csv`,
  })
  const downloadAuditLogs = useDownloadFile({
    fileUrl: `${getPresignedAuditUrlTask.result?.data.url}`,
    fileName: `grade_submissions_audit_log.csv`,
  })

  const pending = useMemo(() => {
    return (
      (getPresignedUrlTask.pending && getPresignedUrlTask.started) ||
      (getPresignedUrlTask.result != null &&
        downloadCentreProgress.pending &&
        downloadCentreProgress.started)
    )
  }, [
    getPresignedUrlTask.pending,
    getPresignedUrlTask.started,
    getPresignedUrlTask.result,
    downloadCentreProgress.pending,
    downloadCentreProgress.started,
  ])

  const aamDownloadLogPending = useMemo(() => {
    return (
      (getPresignedAAMDownloadLogUrlTask.pending &&
        getPresignedAAMDownloadLogUrlTask.started) ||
      (getPresignedAAMDownloadLogUrlTask.result != null &&
        downloadAAMDownloadLogs.pending &&
        downloadAAMDownloadLogs.started)
    )
  }, [getPresignedAAMDownloadLogUrlTask, downloadAAMDownloadLogs])

  const auditDownloadPending = useMemo(() => {
    return (
      (getPresignedAuditUrlTask.pending && getPresignedAuditUrlTask.started) ||
      (getPresignedAuditUrlTask.result != null &&
        downloadAuditLogs.pending &&
        downloadAuditLogs.started)
    )
  }, [
    getPresignedAuditUrlTask.pending,
    getPresignedAuditUrlTask.started,
    getPresignedAuditUrlTask.result,
    downloadAuditLogs.pending,
    downloadAuditLogs.started,
  ])

  useAsyncRun(getPresignedUrlTask.result && downloadCentreProgress)
  useAsyncRun(
    getPresignedAAMDownloadLogUrlTask.result && downloadAAMDownloadLogs
  )
  useAsyncRun(getPresignedAuditUrlTask.result && downloadAuditLogs)

  const [searchInput, setSearchInput] = useState('')

  const searchHandler = () => {
    if (gradingAvailable) {
      routerHistory.push(`/centres/${searchInput}`)
    } else {
      routerHistory.push(`/centres/${searchInput}/manage/teachers`)
    }
  }

  return (
    <>
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row>
            <Col xs={24} md={8}>
              <h3 className={`h5 mb-1 font-weight-bold text-primary`}>
                Internal Dashboard
              </h3>
              <div>
                You can search{' '}
                <b>
                  <u>for a specific centre</u>
                </b>{' '}
                by it’s centre number and go to their dashboard from this page.
              </div>
            </Col>

            <Col className="text-right ca-buttons">
              {isOCR() && (
                <Button
                  disabled={aamDownloadLogPending}
                  color="success"
                  onClick={() => {
                    ReactGA.event({
                      category: 'CA Support',
                      action: 'Download AAM Log',
                    })
                    getPresignedAAMDownloadLogUrlTask.start()
                  }}
                  data-testid="ca-admin-controls-download-aam-log-button"
                >
                  {!aamDownloadLogPending && (
                    <FontAwesomeIcon icon={faArrowToBottom} className="mr-2" />
                  )}
                  {aamDownloadLogPending && (
                    <Spinner size="sm" className="mr-2" />
                  )}
                  <div>Download AAM Log</div>
                </Button>
              )}
              <Button
                disabled={auditDownloadPending}
                color="success"
                onClick={() => {
                  ReactGA.event({
                    category: 'CA Support',
                    action: 'Download Audit Log',
                  })
                  getPresignedAuditUrlTask.start()
                }}
                data-testid="ca-admin-controls-download-submission-audit-log-button"
              >
                {!auditDownloadPending && (
                  <FontAwesomeIcon icon={faArrowToBottom} className="mr-2" />
                )}
                {auditDownloadPending && <Spinner size="sm" className="mr-2" />}
                <div>Download Submission Audit Log</div>
              </Button>
              <Button
                disabled={pending}
                color="success"
                onClick={() => {
                  ReactGA.event({
                    category: 'CA Support',
                    action: 'Download Progress',
                  })
                  getPresignedUrlTask.start()
                }}
                data-testid="ca-admin-controls-download-centre-progress-button"
              >
                {!pending && (
                  <FontAwesomeIcon icon={faArrowToBottom} className="mr-2" />
                )}
                {pending && <Spinner size="sm" className="mr-2" />}
                <div>Download Centre Progress</div>
              </Button>
              <DownloadCentersReportButton />
            </Col>
          </Row>
          <Row className="mt-5 align-items-center">
            <Col md={12}>
              <SearchBar
                val={searchInput}
                placeholder="Search for a centre"
                filterChanged={setSearchInput}
                onClick={searchHandler}
                disableSearch={searchInput.length < 5}
                dataTestid="ca-admin-controls-search-bar"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
